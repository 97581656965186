// AppLayout.js
import React, { useEffect } from 'react';
import Header from '../components/common/Header';
import AdminHeader from '../components/common//AdminHeader';
import DefaultLayout from './DefaultLayout';
import MainLayout from './MainLayout';
import AdminLayout from './AdminLayout';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setState } from './../redux/utilsSlice';
import AbstiLent from './../components/helpers/AbstiLent/AbstiLent'
import { isDev } from './../utils'
const AppLayout = ({ children, requireAuth, requireAdmin }) => {
  const user = useSelector((state) => state.user);
  const overall = useSelector(state => state.utils.overall);
  const location = useLocation();
  useEffect(() => {
    // Redirection si on n'est pas en contexte admin
  }, [requireAdmin]);
  useEffect(() => {
    console.log('URL changée:', location.pathname);
    if (window !== window.parent) {
      window.parent.postMessage({ location: location.pathname }, 'https://laffairearepasser.fr');
    }
  }, [location]);
  return (
    <div className="app-layout">
      {requireAdmin ?
        <AdminLayout>{children}</AdminLayout>
        :
        requireAuth ?
          <MainLayout requireAuth={requireAuth} requireAdmin={requireAdmin}>{children}</MainLayout>
          :
          <DefaultLayout requireAuth={requireAuth} requireAdmin={requireAdmin}>{children}</DefaultLayout>
      }

      {overall && <div id="overall"></div>}
    </div >
  );
}
export default AppLayout;



