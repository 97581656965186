// Customer.js
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import API from '../../api';
import CustomAlert from '../helpers/CustomAlert/CustomAlert';
// import Picture from '../helpers/Picture/Picture';
import PhoneNumber from '../helpers/PhoneNumber/PhoneNumber';
import { debug } from '../../utils'
import InputPassword from './../helpers/InputPassword/InputPassword';
import PasswordChecker from './../helpers/PasswordChecker/PasswordChecker';
const UserParameters = ({ onUpdated = null }) => {
  const { t } = useTranslation();
  const [user, setUser] = useState({});
  const [updatePassword, setUpdatePassword] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(null);
  const [submission, setSubmission] = useState(null);
  const [displayLoyaltyQrc, setDisplayLoyaltyQrc] = useState(false);
  useEffect(() => {
    API.get('/user/parameters').then(response => setUser({ ...response.data, currentPassword: '', userPassword: '' }));
    setUser(user)
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleInputPasswordChange = (whitchOne, password) => {
    const key = whitchOne == 'new' ? 'userPassword' : 'currentPassword';
    setUser({ ...user, [key]: password });
  }
  const handleSubmit = async () => {
    setSubmission(null);
    try {
      if (!user.userEmail.trim()) {
        throw new Error("L'adresse email est requise.");

      }

      if (user.userPhone.trim().length != 13) {
        throw new Error("Le numéro de téléphone n'est pas valide.");
      }

      if (updatePassword && !user.currentPassword.trim()) {
        throw new Error("Le mot de passe actuel est requis.");
      }
      if (updatePassword && !passwordIsValid) {
        throw new Error("Le nouveau mot de passe n'est pas valide.");
      }
      const response = await API.put(`/user/parameters`, user);
      setSubmission({ status: response.status, message: 'Votre compte a été modifié.' });
      onUpdated(response.data)
    } catch (error) {
      console.log(error)
      setSubmission(error);
    }



    return;
  }
  return (
    <div id="UserParameters">
      <form>
        {
          <div className='row'>
            <div className='col-xl-4 col-lg-6 col-md-8'>
              <div className="form-group">
                <label className="form-group-label" required>Email :</label>
                <input
                  type="text"
                  name="userEmail"
                  value={user.userEmail}
                  onChange={handleChange}
                  className="form-control form-control-sm"
                />
              </div>
              <div className="form-group">
                <label className="form-group-label" required>Téléphone :</label>
                <PhoneNumber number={user.userPhone} onUpdate={value => handleChange({ target: { name: 'userPhone', value: value } })} />
              </div>
              <p className='pt-3'><span className='fs-7 pointer text-decoration-hover text-danger' onClick={e => setUpdatePassword(!updatePassword)}>
                {updatePassword == false ? 'Modifier mon mot de passe' : 'Annuler la modification de passe'}
              </span>
              </p>
              {updatePassword == true && <div className="form-group">
                <label className='form-group-label' required>Mot de passe actuel :</label>
                <InputPassword onChange={value => handleInputPasswordChange('current', value)} size='sm' inputClassName='form-control-sm' className='mb-4' />
                <label className='form-group-label' required>Nouveau mot de passe :</label>
                <InputPassword onChange={value => handleInputPasswordChange('new', value)} size='sm' inputClassName='form-control-sm' className='mb-3' />
                <PasswordChecker password={user.userPassword} isValid={e => setPasswordIsValid(e)} />
              </div>
              }
              <div className='py-3'>
                <CustomAlert input={submission} onClose={e => setSubmission(null)} />
              </div>
              <button type="button" onClick={handleSubmit} className="btn  btn-outline-blue">Sauvegarder</button>
            </div>
          </div>
        }
      </form >
    </div >
  );
};
export default UserParameters;

