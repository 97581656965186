import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation, Link, useNavigate, Navigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineArrowLeft } from 'react-icons/ai'; // Importe l'icône souhaitée depuis React Icons
import { debug, formatDate } from './../../utils';
import { clearUser, fetchUser } from './../../redux/userSlice';
import API from '../../api';
import BackLink from '../helpers/backlink/BackLink';
import OrdersList from '../orders/OrdersList';
import OrderDetais from '../orders/OrderDetails';
import CustomerAccount from './CustomerAccount';
import UserParameters from './../user/UserParameters';
function CustomerHome() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { ordId } = useParams();
  console.log(ordId)
  const [user, setUser] = useState({});
  const [customer, setCustomer] = useState({});
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [activeTab, setActiveTab] = useState('');
  // const user = useSelector((state) => state.user);
  useEffect(() => {
    console.log(ordId)

    API.get('/customer').then(response => setCustomer(response.data));
    API.get('/customer/orders').then(response => setOrders(response.data))
  }, [ordId]
  );

  useEffect(() => {

  }, [activeTab]
  );

  useEffect(() => {


    console.log('passe là', orders, ordId)
    if (ordId) {
      const idx = orders.findIndex(ord => ord.ordId == ordId);
      if (idx > -1) {
        setSelectedOrder(orders[idx]);
        setActiveTab('order');
      }

    }


  }, [orders]);


  useEffect(() => {
    // API.get('/customer').then(response => setCustomer(response.data));
    // API.get('/customer/orders').then(response => setOrders(response.data))
  }, []);
  useEffect(() => {
    setActiveTab(location.pathname.replace('/fr/customer/', ''));
  }, [location]);
  const handleOrderSelect = (order) => {
    console.log(order);
    setSelectedOrder(order);
    setActiveTab('order');
  }
  const handleAccountUpdated = (customer) => {
    setCustomer(customer);
  }
  const handleUserUpdated = (user) => {
    /// setCustomer(customer);
  }
  const handleOrderUpdated = () => {
  }
  const handleSignOut = () => {
    dispatch(clearUser());
  }
  return (
    <div id="UserHome">
      <div className='row'>
        <div className="ps-md-5" >
          <div >
            {activeTab == 'orders' &&
              <>
                <h2>Mes commandes</h2>
                <OrdersList orders={orders} onSelect={handleOrderSelect}></OrdersList>
              </>
            }
            {activeTab == 'order' &&
              <>
                <div className='mb-2'>
                  <a href='/fr/customer/orders'>
                    <AiOutlineArrowLeft size="12" className="me-1" /> <span className='pointer text-decoration-hover'>Retour aux commandes</span>
                  </a>
                </div>
                <h2>Commande n° {selectedOrder.ordNumber} du {formatDate(selectedOrder.ordCreatedAt)}</h2>
                <OrderDetais ordId={selectedOrder.ordId} onUpdated={handleOrderUpdated}></OrderDetais>
              </>
            }
            {activeTab == 'account' &&
              <>
                <h2>Mon compte client</h2>
                <CustomerAccount customer={customer} onUpdated={handleAccountUpdated} />
              </>
            }
            {activeTab == 'params' &&
              <>
                <h2>Mes paramètres</h2>
                <UserParameters onUpdated={handleUserUpdated} />
              </>
            }
          </div>
          {false && <button onClick={handleSignOut}>DECO</button>}
        </div>

      </div>
    </div>
  )
}
export default CustomerHome