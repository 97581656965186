
// AdminCities.js
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import API from './../../api';
import InputAutocomplete from '../helpers/InputAutocomplete/InputAutocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { debug, removeAccents, swalAlert } from './../../utils';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdManageSearch } from "react-icons/md";

import './admin-cities.css';
import './../../css/forms.css';
function AdminCities() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [allCities, setAllCities] = useState([]);
  const [activeCities, setActiveCities] = useState([]);
  const [inactiveCities, setInactiveCities] = useState([]);
  const [filters, setFilters] = useState({ active: '', inactive: '' });
  useEffect(() => {
    get_cities();
  }, [
  ]);
  const get_cities = async () => {
    const response = await API.get('/admin/cities');
    setAllCities(response.data);
  }
  const selectCity = async (citId, active) => {
    const data = { citStatus: active ? "active" : "inactive" };
    try {
      await API.put('/admin/city/' + citId, data);
      setAllCities(prevCities => {
        return prevCities.map(cit => {
          if (cit.citId === citId) {
            return { ...cit, citStatus: active ? "active" : "inactive" };
          }
          return cit;
        });
      });
    } catch (error) {
      swalAlert(t(error.response.data.error.message))
    }


  };
  const handleInputChange = (event) => {
    switch (event.target.name) {
      case 'activeFilter':
      case 'inactiveFilter':
        const key = event.target.name.replace('Filter', '');
        setFilters({ ...filters, [key]: event.target.value });
        break;
    }
  }
  const filterMatch = (filter, citName) => {
    return removeAccents(citName).toLowerCase().startsWith(removeAccents(filter).toLowerCase());
  }
  useEffect(() => {
    const activeCitiesCopy = []
    const inactiveCitiesCopy = []
    allCities.forEach((city) => {
      if (city.citStatus === 'active') {
        activeCitiesCopy.push(city);
      } else {
        inactiveCitiesCopy.push(city);
      }
    });
    activeCitiesCopy.sort((a, b) => a.citName.localeCompare(b.citName));
    setActiveCities(activeCitiesCopy);
    setInactiveCities(inactiveCitiesCopy);
  }, [allCities]);
  return (
    <div id="AdminCities">
      <div className='container'>
        <div className='content-title'>
          <h1 >Administration des communes </h1>
        </div>
        <div className='row'>
          <div className='col-4'>
            <h6 className='mb-3'>Communes désservies ({activeCities.length}) :</h6>
            <div className='input-group mb-2'>
              <span className='input-group-text'><MdManageSearch /></span>
              <input type='text' className='form-control form-control-sm' name='activeFilter' value={filters.active} onChange={handleInputChange} />
            </div>
            <div className=' table-wrapper'>
              <table className='table table-striped table-sm'>
                <tbody>
                  {activeCities.map(city => (

                    <> {filterMatch(filters.active, city.citName) &&
                      <tr key={city.citId} >
                        <td>{city.citName}</td>
                        <td className='functions'>
                          <span className='pointer'
                            onClick={e => selectCity(city.citId, false)}
                          ><MdOutlineCheckBox /></span>
                        </td>
                      </tr>}
                    </>
                  ))}
                </tbody>
              </table></div>
          </div>
          <div className='col-1'></div>
          <div className='col-4 ' >
            <h6 className='mb-3'>Communes non désservies :</h6>
            <div className='input-group mb-2'>
              <span className='input-group-text'><MdManageSearch /></span>
              <input type='text' className='form-control form-control-sm' name='inactiveFilter' value={filters.inactive} onChange={handleInputChange} />
            </div>
            <div className=' table-wrapper'>
              <table className='table table-striped table-sm'>
                <tbody>
                  {inactiveCities.map(city => (
                    <>
                      {city.citStatus == 'inactive' && filterMatch(filters.inactive, city.citName) && <tr key={city.citId}>
                        <td>{city.citName}</td>
                        <td className='functions'>
                          <span className='pointer ' onClick={e => selectCity(city.citId, true)}><MdOutlineCheckBoxOutlineBlank /></span>
                        </td>
                      </tr>}
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {false && debug(allCities)}
      </div >
    </div >
  );
}
export default AdminCities;



/*

*/