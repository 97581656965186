// Cart.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import API from './../../api';
import { useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { clearCart, setCart } from '../../redux/cartSlice';
import UserSignIn from './../user/UserSignIn'
import { cloneDeep } from 'lodash';
import './order.css';
import './order-confirm.css';
import { debug, swalAlert, formatDate } from './../../utils';
import CartDetails from './../cart/CartDetails';
import AuthGuard from '../../layouts/AuthGuard';
import UserSignInModal from '../user/UserSignInModal';
function OrderConfirm() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);
  const [address, setAddress] = useState({});
  const [occurrence0, setOccurrence0] = useState({});
  const [occurrence1, setOccurrence1] = useState({});
  const [state, setState] = useState('');
  const [showSignIn, setShowSignIn] = useState(false);
  const [order, setOrder] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setCart({ ...cart, consents: { cgv: false, rgpd: false, finalprice: false, absence: false } }));
    API.get('/address/' + cart.adrId).then(response => {
      setAddress(response.data);
      console.log(response.data)
    });
    API.get('/pickupOccurrenceCity/' + cart.pocId0).then(response => {
      setOccurrence0(response.data);
      console.log(response.data)
    });
    if (cart.pocId1 !== 'poc0000000000000') {
      API.get('/pickupOccurrenceCity/' + cart.pocId1).then(response => {
        setOccurrence1(response.data);
        console.log(response.data)
      });
    }
  }, [])
  useEffect(() => {
    if (state === 'done') {
      dispatch(clearCart());
    }
  }, [state])
  const handleChange = (event) => {
    const cartCopy = cloneDeep(cart);
    let { type, name, value, checked } = event.target;
    console.log(type, name, value, checked)
    const parts = name.split('.');
    switch (type) {
      case 'checkbox':
        value = checked;
        break;
      default:
    }
    if (parts.length == 1) {
      cartCopy[parts[0]] = value;
    }
    else {
      if (!cartCopy[parts[0]]) {
        cartCopy[parts[0]] = {};
      }
      cartCopy[parts[0]][parts[1]] = value;
    }
    dispatch(setCart(cartCopy));
  }
  const handleCreateOrder = async () => {
    if (!user.isSignedIn) {
      setShowSignIn(true);
      return;
    }
    showSignIn && setShowSignIn(false);
    const response = await API.post('/order', cart);
    if (response.data.ordId) {
      setOrder(response.data)
      setState('done');
    } else {
    }
  }
  return (
    <div id="order-signin">
      <div className='container pt5 mb-5'>
        {state !== 'done' && (
          <>
            {cart && cart.articles.length > 0 ? (
              <div>
                <h1>Récapitulatif de commande </h1>
                <div className="row mb-5">
                  <div className="col-md-6 col-lg-4 mb-3">
                    <div style={{ border: '2px solid var(--blue)', padding: '15px', borderRadius: '15px', background: 'var(--blue-1)' }} >
                      <strong>Retrait :</strong><br />
                      <span className='fs-7'>
                        {address?.adrText}, {address?.citPublicName} <br />
                        le {occurrence0?.pocDate ? formatDate(occurrence0.pocDate, 'text') : ''} entre {occurrence0?.pocSlotStart} et {occurrence0?.pocSlotEnd}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 " >
                    <div style={{ border: '2px solid var(--green)', padding: '15px', borderRadius: '15px', background: 'var(--green-1)' }} >
                      <strong>Retour :</strong><br />
                      <span className='fs-7'>
                        {cart.pocId1 !== 'poc0000000000000' ? (
                          <>

                            {address?.adrText}, {address?.citPublicName} <br />
                            le {occurrence1?.pocDate ? formatDate(occurrence1.pocDate, 'text') : ''} entre {occurrence1?.pocSlotStart} et {occurrence1?.pocSlotEnd}
                          </>
                        ) : (
                          <>
                            A la repasserie-pressing, 48, Rue Thiers,  Dieppe<br />
                            A compter du {formatDate(cart.nearestRestitutionDate, 'text')}

                          </>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className='form-group  mb-5'>
                  <label className='form-group-label fs-7'><strong>Indications complémentaires pour le chauffeur :</strong></label><br />
                  {cart.additionnalInformations ? cart.additionnalInformations : 'Aucune'}
                </div>
                <CartDetails cart={cart} articles={cart.articles} onUpdate={e => { }} disabled={true} />
                <p>Si vous avez oublié un article à votre commande, vous pourrez le confier au chauffeur lors de son passage.</p>
                <div className='border rounded py-2 px-2 mb-5'>
                  Montant estimé des frais de transport si vous n'êtes pas abonné : <strong>
                    <span>ALLER&nbsp;:&nbsp;{cart.deliveryCosts[0]}&nbsp;EUR</span>&nbsp;/&nbsp;<span>RETOUR&nbsp;:&nbsp;{cart.deliveryCosts[1]}&nbsp;EUR</span>
                  </strong>
                </div>
                <div className="mb-5" style={{ border: '2px solid var(--gray-9)', padding: '15px', borderRadius: '15px', background: 'var(--gray-1)' }}>
                  <ul className='list-unstyled mb-0'>
                    <li className='mb-0'>
                      <input type="checkbox" checked={cart.consents?.cgv} name="consents.cgv" value="1" className='me-1' onChange={handleChange} /><span className='text-red me-2'>*</span>
                      J'accepte les <a href="/cgv" target="_blank">conditions générales de vente</a>.
                    </li>
                    {false &&
                      <li className='mb-3'>
                        <input type="checkbox" checked={cart.consents?.finalprice} name="consents.finalprice" value="1" className='me-1' onChange={handleChange} /><span className='text-red me-2'>*</span>
                        J'accepte que le prix final des prestations de pressing et/ou de repassage me soit communiqué
                        après la réception, le pesage, le dénombrement de mon linge
                        et la fixation des coûts de transports par la repasserie-pressing.
                      </li>}
                    {false && <li className='mb-3'>
                      <input type="checkbox" checked={cart.consents?.absence} name="consents.absence" value="1" className='me-1' onChange={handleChange} /><span className='text-red me-2'>*</span>
                      Je consens à devoir payer un surcoût de livraison si mon linge ne peut être retiré ou restitué du fait de mon absence.
                    </li>
                    }
                  </ul>
                </div>
                {(!cart.consents || !cart.consents?.cgv) &&
                  <div className='alert alert-warning px-2 py-1 fs-7'>
                    Veuillez accepter les conditions générales de vente.
                  </div>
                }
                <section className='buttons-section' >
                  <Link className='btn btn-blue me-3 my-2' to="/order#cart">Ajouter des articles</Link>
                  <button className='btn btn-outline-blue my-2' onClick={handleCreateOrder} style={{ cursor: 'not-allowed !important' }}
                    disabled={cart.consents?.cgv !== true}>
                    <stroke>
                      Je confirme ma commande
                    </stroke>
                  </button>
                </section>
              </div>
            ) : (
              window.location.href = "/fr/order"
            )}
          </>
        )}
        {state === 'done' && (
          <div style={{
            maxWidth: '500px',
            width: '96%',
            margin: 'auto',
            textAlign: 'center',
            border: '2px solid var(--blue)',
            padding: '15px',
            borderRadius: '10px',
            background: 'var(--blue-1)'
          }}>
            <h2>Merci !</h2>
            <p>Votre commande est confirmée.</p>
            <p>Un courriel vous a été envoyé. Veuillez vérifier dans les courriers indésirables si vous ne le trouvez pas dans la boîte de réception de votre messagerie.</p>
          </div>
        )}

      </div>
      {showSignIn &&
        <UserSignInModal options={{ userType: 'Customer', onAbort: e => setShowSignIn(false) }} onClose={e => setShowSignIn(false)} show={true} onSuccess={handleCreateOrder} />
      }
    </div>
  );
}
export default OrderConfirm;

/*

        {debug(cart)}

|| !cart.consents?.finalprice || !cart.consents?.absence

<button className='btn btn-outline-blue my-2' onClick={handleCreateOrder} style={{ cursor: 'not-allowed !important' }}
                    disabled={!cart.consents || !cart.consents?.rgpd || !cart.consents?.finalprice || !cart.consents?.absence}>
                    <stroke>
                      Je confirme ma commande
                    </stroke>
                  </button>

*/