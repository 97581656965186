import L from 'leaflet';

export const redIcon = new L.Icon({
  iconUrl: '/img/maps/red-marker.png',
  iconSize: [15, 24],
});

export const greyIcon = new L.Icon({
  iconUrl: '/img/maps/grey-marker.png',
  iconSize: [15, 24],
});