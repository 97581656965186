import { useEffect } from 'react';
import './adminLayout.css';
import AdminGuard from './AdminGuard';
import AdminHeader from './../components/common/AdminHeader';
const AdminLayout = ({ children }) => {

  useEffect(() => {
    document.body.classList.add('admin-layout');
    return () => {
      document.body.classList.remove('admin-layout');
    };
  }, []);
  return (
    <>
      <AdminGuard>
        <div className="admin-layout">
          <AdminHeader />
          <div id="admin-content">
            {children}
          </div>
        </div>
      </AdminGuard>
    </>
  );
};
export default AdminLayout;