
// AdminPartners.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { MdOpenInNew, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdManageSearch, MdAdd } from "react-icons/md";

import { debug, formatDate, formatPrice, swalAlert } from '../../../utils'
import { BiShow, BiHide } from "react-icons/bi";
import { FaRegFilePdf } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import API from '../../../api';
import AddressSelector from './../../cities/AdresssSelectorCopy';
const AdminPartners = ({ }) => {
  const [partners, setPartners] = useState([]);
  const [orderedPartners, setOrderedPartners] = useState([]);
  const [cities, setCities] = useState([]);
  const [modalPartner, setModalPartner] = useState(null);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    get_cities();
    get_partners();
  }, []);

  const get_cities = async () => {
    const response = await API.get('/admin/cities');
    setCities([...response.data]);
  };

  useEffect(() => {
    // Fonction pour trier les partenaires par nom
    const sortPartnersByName = partners => {
      return partners.sort((a, b) => a.partName.localeCompare(b.partName));
    };

    // Mise à jour de orderedPartners chaque fois que partners est modifié
    setOrderedPartners(sortPartnersByName([...partners]));
  }, [partners]); // Dépendance à partners pour réactiver l'effet lors de sa modification



  const get_partners = async () => {
    const response = await API.get('/admin/partners');
    console.log(response.data)
    setPartners([...response.data]);
  };

  const setModalParnerAddress = (data) => {
    if (data) {

      setModalPartner({
        ...modalPartner,
        partCityId: data.adrCityId,
        partAreaId: data.adrAreaId,
        partAddressId: data.adrId,
        partDeliveryZone: data.citDeliveryZone
      })
      console.log(data)
    }
  }
  const handleSave = async () => {
    console.log(modalPartner)
    if (!modalPartner.partName || modalPartner.partName.length == 0) {
      swalAlert("Le nom de l'entreprise est requis.")
      return
    }
    if (!modalPartner.partCityId || modalPartner.partCityId.length == 0) {
      swalAlert("La ville de l'entreprise est requise.")
      return
    }
    if (!modalPartner.partAddressId || modalPartner.partAddressId.length == 0) {
      swalAlert("L'adresse de l'entreprise est requise.")
      return
    }
    try {
      let response;
      if (!modalPartner.partId != '') {
        response = await API.post('/admin/partner', modalPartner);
        setPartners([...partners, response.data]);
      } else {
        response = await API.put('/admin/partner/' + modalPartner.partId, modalPartner);
        if (response.data.partStatus === 'deleted') {
          setPartners(partners.filter(partner => partner.partId !== response.data.partId));
        } else {
          setPartners(partners.map(partner => {
            if (partner.partId === response.data.partId) {
              return response.data;
            }
            return partner;  // Retourne les partenaires non modifiés
          }));
        }
      }
      setModalPartner(null);
    } catch (error) {
      console.log(error)

    }
  }

  return (
    <>
      <div>
        <div className='container'>
          <div className='d-flex align-items-center justify-content-between'>
            <div className='content-title'>
              <h1>Entreprises partenaires</h1>
            </div>
            <div className=''>
              <button className='btn btn-outline-warning' onClick={e => setModalPartner({ partId: '', partStatus: 'active' })}>Ajouter une entreprise</button>
            </div>
          </div>
          <p className='fw-bold'>Entreprises éligibles aux retraits et retours</p>
          <table className='table'>
            <thead>
              <tr>
                <th style={{ width: '240px' }}>Entreprise</th>
                <th className='text-center' style={{ width: '240px' }}>Ville</th>
                <th></th>
                <th style={{ width: '40px' }}>Livraison</th>
                <th className='text-center' style={{ width: '120px' }}>Statut</th>
                <th style={{ width: '40px' }}></th>
              </tr>
            </thead>
            <tbody>
              {orderedPartners.map((partner, idx) => (
                <tr key={idx}>
                  <td>{partner.partName}</td>

                  <td className='text-center'>{cities.find(c => c.citParentId == partner.partCityId)?.citPublicName}</td>
                  <td>{partner.partCityId}</td>
                  <td className='text-center'>{partner.partDeliveryZone}</td>
                  <td className='text-center'>{partner.partStatus == 'active' ? 'Actif' : 'Désactivé'}</td>
                  <td><span className="pointer" onClick={e => setModalPartner({ ...partner })}><MdOpenInNew /></span></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {debug(cities)}
      </div>
      {modalPartner && (
        <div className="modal show" tabIndex="-1" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {
                    modalPartner?.partName ? modalPartner.partName : 'Nouvelle entreprise'
                  }
                </h5>
                <button type="button" className="btn-close" onClick={e => setModalPartner(null)}></button>
              </div>
              <div className="modal-body style={{ minHeight: '450px' }}">
                <div className='form-group form-group-sm'>
                  <label className='form-group-label' required>Nom de l'entreprise : </label>
                  <input
                    type="input"
                    className='form-control'
                    name='partName'
                    value={modalPartner?.partName}
                    onChange={e => setModalPartner({ ...modalPartner, partName: e.target.value })}
                  />
                </div>
                <div className='form-group form-group-sm' >
                  <AddressSelector
                    selectedCityId={modalPartner?.partCityId}
                    selectedAddressId={modalPartner?.partAddressId}
                    onAddressSelected={setModalParnerAddress}
                    disabled={false}
                    readonly={false}
                    inputClassName=''
                    onChange={e => { }}
                  />
                </div>
                <div className='form-group form-group-sm mb-5'>
                  <label className='form-group-label' required>Statut : </label>
                  <select
                    type="input"
                    className='form-control'
                    name='partName'
                    value={modalPartner?.partStatus}
                    onChange={e => setModalPartner({ ...modalPartner, partStatus: e.target.value })}
                  >
                    <option value="active">Actif</option>
                    <option value="inactive">Desactivé</option>
                    {modalPartner.partId != '' && <option value="deleted">Supprimer</option>}
                  </select>

                </div>
                <div className='form-group form-group-sm'>
                  <button className='btn btn-outline-warning' onClick={handleSave}>Sauvegader</button>
                </div>
              </div>
              <div className="modal-footer">
              </div>
            </div>
          </div>

        </div>
      )}

    </>
  )

}
export default AdminPartners;