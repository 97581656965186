import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; // Importe useSelector
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
// import ProdCodeStock from './../ProdCodeStock';
import AdminPickupFeatures from './incAdminPickup/AdminPickupFeatures';
import AdminPickupCities from './incAdminPickup/AdminPickupCities';
import AdminPickupOccurrences from './incAdminPickup/AdminPickupOccurrences';
import API from '../../../api'
import { debug, removeAccents, swalAlert } from './../../../utils';
import './../../../css/forms.css';
import './../../../css/tabs.css';
import './admin-pickup-modal.css';
// import 'sweetalert2/dist/sweetalert2.min.css';
const AdminPickup = ({ pickup, onClose, onCreated, onUpdated }) => {
  const [modalPickup, setModalPickup] = useState({ dirtyFields: [] });
  const [formStatus, setFormStatus] = useState('');
  const [activeTab, setActiveTab] = useState('features'); // || occurrences
  const [availableCities, setAvailableCities] = useState([]);
  useEffect(() => {
    get_available_cities();
  }, []);
  useEffect(() => {
    setModalPickup({
      picName: '',
      picDayA: '',
      picDayB: '',
      picFirstOccurrenceWeek: '',
      picDefaultSlotStartA: '',
      picDefaultSlotEndA: '',
      picDefaultSlotStartB: '',
      picDefaultSlotEndB: '',
      picMaxBooks: '',
      ...pickup
    });
  }, [pickup]);
  const get_available_cities = async () => {
    const query = { citStatus: 'active' }
    const response = await API.get('/admin/cities?query=' + JSON.stringify(query));
    setAvailableCities(response.data);
  }
  const handlePickupChange = (payload, tab = '', updateDirtyFields = true) => {
    const newDirtyFields = new Set([...modalPickup.dirtyFields, ...(updateDirtyFields ? Object.keys(payload) : [])]);
    switch (tab) {
      default:
        setModalPickup({ ...modalPickup, ...payload, dirtyFields: [...newDirtyFields] })
    }
  }
  const formIsValid = () => {
    return (
      modalPickup.picName !== "" &&
      modalPickup.picDayA !== "" &&
      modalPickup.picDefaultSlotStartA !== "" &&
      modalPickup.picDefaultSlotEndA !== "" &&
      (modalPickup.picDefaultSlotStartA < modalPickup.picDefaultSlotEndA) &&
      modalPickup.picDayB !== "" &&
      (modalPickup.picDefaultSlotStartB < modalPickup.picDefaultSlotEndB) &&
      modalPickup.picDefaultSlotStartB !== "" &&
      modalPickup.picDefaultSlotEndB !== "" &&
      modalPickup.picFirstOccurrenceWeek !== "" &&
      modalPickup.picMaxBooks !== ""
    );
  }
  const handleSave = async () => {
    let response;
    if (modalPickup.picId) {
      response = await API.put('/admin/pickup/' + modalPickup.picId, modalPickup);
      onUpdated(response.data)
    } else {
      response = await API.post('/admin/pickup', modalPickup);
      onCreated(response.data)
    }
    setModalPickup({ ...response.data, dirtyFields: [] });
  }
  return (
    <div className={`${activeTab}`}>
      <ul className='nav nav-tabs'>
        <li className='nav-item'><a className={`nav-link ${activeTab === 'features' ? 'active' : ''}`} onClick={e => setActiveTab('features')} >Caractéristiques <span className='required'></span></a></li>
        <li className='nav-item'><a className={`nav-link ${activeTab === 'cities' ? 'active' : ''}`} onClick={e => setActiveTab('cities')}>Communes <span className='required'></span></a></li>
        <li className='nav-item'><a className={`nav-link ${activeTab === 'occurrences' ? 'active' : ''}`} onClick={e => setActiveTab('occurrences')}>Occurrences</a></li>
        <li className='nav-item ms-auto'>
          <button className='btn  btn-outline-secondary' onClick={handleSave} disabled={!formIsValid()} >
            {formStatus == 'saving' && <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>}
            Sauvegader
          </button>
        </li>
      </ul>
      <div className=' tab-contents'>
        {(activeTab === 'features' && !formIsValid()) && <div className="text-center small text-red">Le formulaire est incomplet ou contient des erreurs.</div>}
        {activeTab === 'features' && <AdminPickupFeatures pickup={modalPickup} onChange={handlePickupChange} />}
        {activeTab === 'cities' && <AdminPickupCities pickup={modalPickup} availableCities={availableCities} onChange={handlePickupChange} />}
        {activeTab === 'occurrences' && <AdminPickupOccurrences pickup={modalPickup} onChange={handlePickupChange} />}
        {/* activeTab === 'password' && <AdminCustomerPassword onChange={password => handleCustomerChange('password', password)} defaultValue={editedCustomer.password} /> */}
      </div>
      {debug(modalPickup, 0)}
      {debug(modalPickup.dirtyFields, 1)}
    </div>
  )
}
export default AdminPickup;