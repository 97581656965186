import React from 'react';

function TimeSelect({ start = "08:00", end = "18:00", step = 30, selectClassName = '', name = '', value, onChange = null }) {
  // Function to generate options
  const generateOptions = () => {
    const options = [];
    // Loop to generate hours from start to end with a step of 30 minutes
    for (let h = 0; h <= 23; h++) {
      for (let m = 0; m < 60; m += step) {
        // Formatting hour and minutes
        const hour = ('0' + h).slice(-2); // Add leading zero if necessary
        const minutes = ('0' + m).slice(-2); // Add leading zero if necessary
        const time = ('0' + h).slice(-2) + ':' + ('0' + m).slice(-2);
        // Add option to the options list
        if (time >= start && time <= end) {
          options.push(
            <option key={`${hour}:${minutes}`} value={`${hour}:${minutes}`}>
              {`${hour}:${minutes}`}
            </option>
          )
        };
      }
    }
    return options;
  };
  return (
    <select
      className={`form-control ${selectClassName}`}
      onChange={onChange}
      name={name}
      value={value}
    >
      <option value=""></option>
      {generateOptions()}
    </select>
  );
}

export default TimeSelect;
