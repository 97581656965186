// cart.utils.js
export function getCartCumuls(articles) {
  const cartCumuls = {
    itemsCounts: {
      all: 0,
      kilo: 0,
      piece: 0,
    },
    totalAmounts: {
      all: 0,
      kilo: 0,
      piece: 0,
    },
  }
  if (articles) {
    articles.forEach(art => {
      cartCumuls.itemsCounts.all += art.quantity;
      cartCumuls.totalAmounts.all += Number(art.totalPrice);
      cartCumuls.itemsCounts[art.unity] += art.quantity;
      cartCumuls.totalAmounts[art.unity] += Number(art.totalPrice);

    })
  }
  return cartCumuls;
}



