import React from 'react';
import { debug } from '../../../utils';
const FluxComponent = ({ ordFlows }) => {
  const renderFlux = (flux, details) => {

    if (!details) {
      return null;
    }
    let circleColor = '#eaeaea'
    let diskColor = '#fafafa';
    if (details.count && details.count > 0) {
      circleColor = '#ffc107'
    }
    if (details.status === 'done') {
      circleColor = '#198754'
      diskColor = '#198754';
    }
    return (
      <div style={{ margin: '0 10px' }} key={flux} className='d-flex flex-column align-items-center justify-content-center'>
        <div style={{ textTransform: 'uppercase', fontSize: '9px' }}>{flux.substr(0, 4)}</div>
        <div style={{
          width: '16px',
          height: '16px',
          borderRadius: '10px',
          backgroundColor: circleColor,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}>
          <a style={{
            width: '12px',
            height: '12px',
            borderRadius: '10px',
            backgroundColor: diskColor,
            position: 'absolute',
            top: '2px',
            left: '2px',
            display: 'block',
          }} title={details.count} />
        </div>
      </div>
    );
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {ordFlows?.pressing ? Object.entries(ordFlows).map(([flux, details]) => renderFlux(flux, details)) : null}
    </div>
  );
};
export default FluxComponent;
/*
      {debug(ordFlows)}
*/