import { useTranslation } from 'react-i18next';
import TimeSelect from '../../../helpers/TimeSelect/TimeSelect';
import moment from 'moment';
import { debug, formatDate, swalAlert } from './../../../../utils';

const AdminPickupModalFeatures = ({ pickup, onChange }) => {
  const { t } = useTranslation();

  const handleChange = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    const payload = { [key]: value };
    switch (key) {
      case 'picFirstOccurrenceWeek':
        break;
    }
    onChange({ ...payload });
  };

  function getFirstOccurrenceDate(selectedDay, weekValue) {
    const [year, week] = weekValue.split('-W');
    const date = moment(weekValue);
    date.add(selectedDay - 1, 'days'); // Ajuste au jour sélectionné
    return date.format('YYYY-MM-DD');
  }
  return (
    <form id="AdminPickupModalFeatures">
      <div className="form-group">
        <label className="form-group-label" required>
          Nom :
        </label>
        <input className="form-control" type="text" name="picName" value={pickup.picName} onChange={handleChange} />
      </div>
      <div className='row'>
        <div className='col-6'>
          <div className="form-group ">
            <label className="form-group-label" required>
              Jour semaines A (impaires) :
            </label>
            <div className="d-flex pt-2">
              {[1, 2, 3, 4, 5, 6].map(idx => (
                <label className="radio-label me-3" key={idx}>
                  <input type="radio" name="picDayA" value={idx} checked={parseInt(pickup.picDayA) === idx} onChange={handleChange}
                  />
                  {t(`day-${idx}`)}
                </label>
              ))}
            </div>
          </div>
        </div>
        <div className='col-6'>
          <div className="form-group">
            <label className="form-group-label" required>
              Plage horaire par défaut semaine A :
            </label>
            <div className='input-group '>
              <span className='input-group-text'>De : </span>
              <TimeSelect
                onChange={handleChange}
                start={'07:00'}
                end={'18:00'}
                selectClassName='form-control-sm'
                name='picDefaultSlotStartA'
                value={pickup.picDefaultSlotStartA}
              />
              <span className='input-group-text'>A : </span>
              <TimeSelect
                onChange={handleChange}
                start={pickup.picDefaultSlotStartA}
                end={'18:00'}
                selectClassName='form-control-sm'
                name='picDefaultSlotEndA'
                value={pickup.picDefaultSlotEndA}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-6'>
          <div className="form-group">
            <label className="form-group-label" required>
              Jour semaines B (paires) :
            </label>
            <div className="d-flex pt-2">
              {[1, 2, 3, 4, 5, 6].map(idx => (
                <label className="radio-label me-3" key={idx}>
                  <input type="radio" name="picDayB" value={idx} checked={parseInt(pickup.picDayB) === idx} onChange={handleChange}
                  />
                  {t(`day-${idx}`)}
                </label>
              ))}
            </div></div>
        </div>
        <div className='col-6'>
          <div className="form-group">
            <label className="form-group-label" required>
              Plage horaire par défaut semaine B :
            </label>
            <div className='input-group '>
              <span className='input-group-text'>De : </span>
              <TimeSelect
                onChange={handleChange}
                start={'07:00'}
                end={'18:00'}
                selectClassName='form-control-sm'
                name='picDefaultSlotStartB'
                value={pickup.picDefaultSlotStartB}
              />
              <span className='input-group-text'>A : </span>
              <TimeSelect
                onChange={handleChange}
                start={pickup.picDefaultSlotStartB}
                end={'18:00'}
                selectClassName='form-control-sm'
                name='picDefaultSlotEndB'
                value={pickup.picDefaultSlotEndB}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='row'>

        <div className='col-6' >
          <div className="form-group">
            <label className="form-group-label" required>
              Semaine de la première collecte :
            </label>
            <input className="form-control" type="week" name="picFirstOccurrenceWeek" value={pickup.picFirstOccurrenceWeek} onChange={handleChange} />
          </div>
        </div>
        <div className='col-6'>
          <div className='form-group'>
            <label className="form-group-label" required>
              Maximum de réservations par tournée :
            </label>
            <input className="form-control text-center" type="number" name="picMaxBooks" value={pickup.picMaxBooks} onChange={handleChange} style={{ width: '80px' }} />
          </div>
        </div>
      </div>


      <div className='row'>
        <div className='col-6'>
          <div className="form-group">
            <label className="form-group-label" required>
              Statut :
            </label>
            <select className='form-control' name="picStatus" onChange={handleChange} value={pickup.picStatus}>
              <option value="active" >Activée</option>
              <option value="inactive">Désactivée</option>
              <option value="archived">Archivée</option>
            </select>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AdminPickupModalFeatures;
/*
selected={pickup.picStatus === 'active'}
 selected={pickup.picStatus === 'inactive'}
*/