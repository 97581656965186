import React, { useState, useEffect, useRef } from 'react';
import { debug, download, formatDate, swalAlert, swalConfirm } from './../../../../utils';
import { BiCaretDown, BiCaretUp } from "react-icons/bi";
import moment from 'moment';
import Switcher from './../../../helpers/Switcher/Switcher'
import BadgeCounter from './../../../helpers/BadgeCounter/BadgeCounter'
import API from './../../../../api';
import { BsArrowLeft, BsArrowRight, BsPause, BsPrinter } from "react-icons/bs";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PickupOccurrenceDaySelector from './PickupOccurrenceDaySelector'

import { MdDragHandle } from "react-icons/md";

import TimeSelect from '../../../helpers/TimeSelect/TimeSelect';
// import './../../../css/tabs.css'
import './admin-pickups-occurrences.css'
function AdminPickupModalOccurrences({ pickup, onChange }) {

  const [citiesById, setCitiesById] = useState({});



  const [deployedOccurrences, setDeployedOccurrences] = useState([]);





  useEffect(() => {
    const cities = {};
    console.log(pickup.cities)
    pickup.cities.forEach(city => {
      cities[city.citId] = city
    })
    setCitiesById(cities);
    console.log('cities', cities)


  }, []);



  const handleCreateOccurrences = async () => {
    const url = '/admin/pickup/' + pickup.picId + '/create_occurrences';
    try {
      const response = await API.post(url);
      onChange({ occurrences: response.data, has_occurrences: true }, 'occurrences', false); // false : pas de mise à jour de dirtyFields
    } catch (error) {
      swalAlert(error.response.data.error.message);
    }
  }
  const handlePickupsNavClick = async (sens) => {
    let url = '/admin/pickup/' + pickup.picId + '/occurrences';
    let ref = ''
    switch (sens) {
      case 'before':
        ref = pickup.occurrences[0].poDate;
        break;
      case 'now':
        ref = moment().day(0).format('YYYY-MM-DD');
        sens = 'after';
        break;
      case 'after':
        ref = pickup.occurrences[pickup.occurrences.length - 1]['poDate'];
        break;
    }
    url += '?sens=' + sens + "&ref=" + ref;
    const response = await API.get(url);
    if (response.data.length > 0) {
      onChange({ occurrences: response.data });
    }
  }
  const handleOccurenceChange = (event, occurrence) => {
    try {
      const name = event.target.name;
      const newValue = event.target.value;
      const occurrenceCopy = { ...occurrence };
      occurrenceCopy[name] = newValue;
      if (name == 'poStatus') {
        if (occurrence.order_items.length > 0) {
          throw ('Cette collecte contient des commandes et ne peut être désactivée.');
        }
        occurrenceCopy.cities.forEach(city => city.pocStatus = newValue)
      }
      const occurrencesCopy = [...pickup.occurrences];
      const occurrenceIndex = occurrencesCopy.findIndex(occ => occ.poId === occurrenceCopy.poId);
      if (occurrenceIndex !== -1) {
        occurrencesCopy[occurrenceIndex] = { ...occurrenceCopy };
        onChange({ occurrences: occurrencesCopy });
      }
      onChange({ occurrences: occurrencesCopy });
    } catch (e) {
      swalAlert(e);
    }
  }
  const handleOccurenceCityChange = (event, city) => {
    try {
      const name = event.target.name;
      const newValue = event.target.value;
      const occurrence = pickup.occurrences.find(po => po.poId === city.pocOccurrenceId)
      if (occurrence.order_items.filter(oi => oi.oiPickupOccurrenceCityId === city.pocId).length > 0) {
        throw ('Cette collecte contient des commandes et ne peut être désactivée.');
      }
      const cityesCopy = occurrence.cities;
      const cityIndex = cityesCopy.findIndex(poc => poc.pocId === city.pocId);
      cityesCopy[cityIndex] = { ...city, [name]: newValue };
      const occurrencesCopy = pickup.occurrences;
      const occurrenceIndex = occurrencesCopy.findIndex(occ => occ.poId === occurrence.poId);
      occurrencesCopy[occurrenceIndex] = { ...occurrence, cities: cityesCopy };
      onChange({ occurrences: occurrencesCopy });
    } catch (e) {
      swalAlert(e);
    }
  }
  const toggleDetailsVisibility = (poId) => {
    const idx = deployedOccurrences.indexOf(poId);
    if (idx === -1) {
      setDeployedOccurrences([...deployedOccurrences, poId]);
    } else {
      const deployedOccurrencesCopy = deployedOccurrences;
      deployedOccurrencesCopy.splice(idx, 1);
      setDeployedOccurrences([...deployedOccurrencesCopy]);
    }
  }
  useEffect(() => {
    console.clear();
  }, []);
  const [flowsCounts, setFlowsCounts] = useState([]);
  useEffect(() => {
    const flowsCount = {};
    if (pickup.occurrences && pickup.occurrences.length > 0) {
      pickup.occurrences.forEach(occurrence => {
        flowsCount[occurrence.poId] = {
          '_collectes': occurrence.order_items.filter(oi => oi.oiFlow === 'collecte').length,
          '_restitutions': occurrence.order_items.filter(oi => oi.oiFlow === 'restitution').length,
        };
        occurrence.cities.forEach(city => {
          flowsCount[occurrence.poId][city.citId] = {
            '_collectes': occurrence.order_items.filter(oi => oi.oiFlow === 'collecte' && oi.oiCityId == city.citId).length,
            '_restitutions': occurrence.order_items.filter(oi => oi.oiFlow === 'restitution' && oi.oiCityId == city.citId).length,
          };
        })
      });
      setFlowsCounts(flowsCount);
    }
  }, [pickup.occurrences]);
  const handleBadgeClick = async (item) => {
    const response = await API.get('/admin/occurrence/' + item.poId + '?include=items,customer,products');
  }
  const handlePrintPickupOccurrence = (occurrence) => {
    download(`/admin/occurrence/${occurrence.poId}/pdf`, () => { })
  }
  const orderOccurrencesCities = (occurrences) => {
    const occurrencesCopy = [...occurrences];
    occurrencesCopy.forEach((occurrence, idx) => {
      const citiesCopy = [...occurrence.cities];
      citiesCopy.sort((city1, city2) => {
        const slot1 = `${city1.pocSlotStart}-${city1.pocSlotEnd}`;
        const slot2 = `${city2.pocSlotStart}-${city2.pocSlotEnd}`;
        if (slot1 < slot2) return -1;
        if (slot1 > slot2) return 1;
        return 0;
      });
      occurrencesCopy[idx].cities = citiesCopy;
    });
    onChange({ occurrences: occurrencesCopy });
  };
  const handleTimeChange = (poId, citId, event) => {
    const { name, value } = event.target;
    const occurrences = [...pickup.occurrences];
    const idx = occurrences.findIndex(occurrence => occurrence.poId === poId);
    const occurrence = { ...occurrences[idx] };
    const cityIdx = occurrence.cities.findIndex(city => city.citId === citId);
    occurrence.cities[cityIdx][name] = value;
    occurrences[idx] = occurrence;
    orderOccurrencesCities(occurrences); // S'assurer que les villes sont triées avant de mettre à jour l'état
  };
  const handleDragEnd = (data) => {
    const { poId, source, destination, draggableId } = data;
    if (destination && (source.index !== destination.index)) {
      const occurrences = [...pickup.occurrences];
      const idx = occurrences.findIndex(occurrence => occurrence.poId === poId);
      const occurrence = { ...occurrences[idx] };
      const citiesCopy = [...occurrence.cities];
      const draggedCity = citiesCopy.find(city => city.pocId === draggableId);
      citiesCopy.splice(source.index, 1);
      citiesCopy.splice(destination.index, 0, draggedCity);
      citiesCopy.forEach((city, index) => {
        city.pocOrder = index; // Index commence à 0, donc on ajoute 1
      });
      occurrences[idx].cities = citiesCopy;
      orderOccurrencesCities(occurrences);
    }
  };
  const handlePickupOccurrenceDateChange = async (newDate, poId) => {
    const index = pickup.occurrences.findIndex(po => po.poId === poId);
    if (pickup.occurrences[index].order_items.length > 0) {
      swalAlert('Cette collecte contient des commandes. Sa date ne peut être modifiée.');
    } else {
      const response = await swalConfirm('Déplacer la date de cette occurrence au<br/>' + formatDate(newDate, 'text') + ' ?');
      // if (response == true) {
      // try {
      // const response = await API.put(`/admin/occurrence/${poId}`, { poDate: newDate })
      const occurrencesCopy = [...pickup.occurrences];
      // occurrencesCopy[index] = { ...response.data }
      occurrencesCopy[index] = { ...occurrencesCopy[index], poDate: newDate }
      onChange({ occurrences: occurrencesCopy });
      // } catch (error) {
      //   swalAlert(error.response.data.error.message);
      // }
      //}
    }
  }
  return (
    <div id="AdminPickupModalOccurrences">
      <div>
        {pickup.occurrences ? (
          pickup.has_occurrences ? (
            <>
              <div className='d-flex justify-content-between pt-2 pb-2'>
                <a className='pointer' onClick={e => handlePickupsNavClick('before')}><BsArrowLeft size={20} title="Collectes précédentes" /></a>
                <a className='pointer' onClick={e => handlePickupsNavClick('now')}><BsPause size={20} title="Collectes précédentes" /></a>
                <a className='pointer' onClick={e => handlePickupsNavClick('after')}><BsArrowRight size={20} title="Collectes suivantes" /> </a>
              </div>
              <hr className='mt-0 mb-2' />
              <table className='table table-sm table-stiped table-bordered'>
                <thead>
                  <tr>
                    <td colSpan={3}>
                    </td>
                    <td className='text-center'>
                      Collectes
                    </td>
                    <td className='text-center'>
                      Restitutions
                    </td>
                    <td style={{ width: '40px' }}>
                    </td>
                    <td style={{ width: '40px' }}>
                    </td>
                    <td style={{ width: '30px' }}>
                    </td>
                  </tr>
                </thead>
                {pickup.occurrences.map((occurrence, idx) => (
                  <React.Fragment key={idx}>
                    <thead>
                      <tr>
                        <td colSpan={2} style={{ verticalAlign: 'middle' }}>

                          <PickupOccurrenceDaySelector defaultDate={occurrence.poDate} onUpdate={date => handlePickupOccurrenceDateChange(date, occurrence.poId)} />
                        </td>
                        <td colSpan={1}>
                          <div className='input-group input-group-sm'>
                            <span className='input-group-text'>Réservations maxi : </span>
                            <input
                              className='form-control flex-grow-0'
                              type='number'
                              value={occurrence.poMaxBooks}
                              name="poMaxBooks"
                              style={{ width: '80px' }}
                              onChange={e => handleOccurenceChange(e, occurrence)}
                            />
                          </div>
                        </td>

                        <td className='text-center' style={{ width: '55px' }}>
                          <BadgeCounter count={flowsCounts?.[occurrence.poId]?.['_collectes']} className='bg-secondary badge-sm pointer' hideOnZero={true} onClick={e => handleBadgeClick(occurrence)} />
                        </td>
                        <td className='text-center' style={{ width: '55px' }}>
                          <BadgeCounter count={flowsCounts?.[occurrence.poId]?.['_restitutions']} className='bg-secondary badge-sm' hideOnZero={true} />
                        </td>
                        <td>
                          <Switcher className='switcher-sm' onChange={e => handleOccurenceChange(e, occurrence)} name="poStatus" onValue='active' offValue='inactive' value={occurrence.poStatus} />
                        </td>
                        <td><a onClick={e => handlePrintPickupOccurrence(occurrence)} title="Fiche tournée"><BsPrinter size={20} /></a></td>
                        <td>
                          <span onClick={e => toggleDetailsVisibility(occurrence.poId)}>
                            {deployedOccurrences.includes(occurrence.poId) ? <BiCaretUp size={25} /> : <BiCaretDown size={25} />} {occurrence.show_details === true}
                          </span>
                        </td>
                      </tr>
                    </thead>
                    {deployedOccurrences.includes(occurrence.poId) &&
                      <DragDropContext onDragEnd={event => handleDragEnd({ poId: occurrence.poId, ...event })}>
                        <Droppable droppableId="occurrence-cities">
                          {(provided) => (
                            <tbody {...provided.droppableProps} ref={provided.innerRef}>

                              {occurrence.cities.map((city, index) => (
                                <Draggable key={city.pocId} draggableId={city.pocId} index={index}>
                                  {(provided) => (
                                    <tr
                                      {...provided.draggableProps}

                                      ref={provided.innerRef}
                                      key={city.pocId}
                                      className={city.pocStatus}
                                    >
                                      <td className='drag-handle text-center' style={{ width: '15px' }} {...provided.dragHandleProps}>
                                        <MdDragHandle />
                                      </td>
                                      <td>
                                        {citiesById[city.pocCityId].citName}
                                      </td>
                                      <td className='text-center'>
                                        <div className={`input-group input-group-sm  alert p-0 m-0 ${city.pcSlotStart >= city.pcSlotEnd ? 'alert-danger' : null}`}>
                                          <span className='input-group-text'>De : </span>
                                          <TimeSelect
                                            onChange={event => handleTimeChange(occurrence.poId, city.citId, event)}
                                            start={occurrence.poDefaultSlotStart}
                                            end={occurrence.poDefaultSlotEnd}
                                            step={15}
                                            selectClassName='form-control-sm'
                                            name='pocSlotStart'
                                            value={city.pocSlotStart}
                                          />
                                          <span className='input-group-text'>A : </span>
                                          <TimeSelect
                                            onChange={event => handleTimeChange(occurrence.poId, city.citId, event)}
                                            start={occurrence.poDefaultSlotStart}
                                            end={occurrence.poDefaultSlotEnd}
                                            step={15}
                                            selectClassName='form-control-sm'
                                            name={`pocSlotEnd`}
                                            value={city.pocSlotEnd}
                                          />
                                        </div>
                                      </td>
                                      <td className='text-center' style={{ width: '65px' }}>
                                        <BadgeCounter count={flowsCounts?.[occurrence.poId]?.[city.citId]?.['_collectes']} className='bg-secondary badge-sm' hideOnZero={true} />
                                      </td>
                                      <td className='text-center' style={{ width: '65px' }}>
                                        <BadgeCounter count={flowsCounts?.[occurrence.poId]?.[city.citId]?.['_restitutions']} className='bg-secondary badge-sm' hideOnZero={true} />
                                      </td>

                                      <td className='text-end'>
                                        <Switcher
                                          className='switcher-sm'
                                          onChange={e => handleOccurenceCityChange(e, city)}
                                          name="pocStatus"
                                          onValue='active'
                                          offValue='inactive'
                                          value={city.pocStatus}
                                          disabled={occurrence.poStatus == 'inactive'} />
                                      </td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </DragDropContext>
                    }
                  </React.Fragment>
                ))}
              </table>
            </>
          ) : (
            <div className='text-center pt-3 mb-5'>
              {pickup.picFirstOccurrenceWeek !== '' ? (
                <>
                  {pickup.dirtyFields.length == 0 && (
                    <button className='btn btn-outline-secondary' onClick={handleCreateOccurrences} disabled={pickup.dirtyFields.includes('picFirstOccurrenceWeek')}>
                      Créer les premières collectes
                    </button>
                  )}

                  {pickup.dirtyFields.length > 0 && (
                    <div className='alert alert-warning small py-2 py-3 mx-auto mt-5' style={{ maxWidth: '350px' }}>
                      <p>Veuillez sauvegarder les modifications avant de générer les premières collectes.</p>
                    </div>
                  )}

                </>
              ) : (
                <div>Veuillez définir la semaine de la première collecte</div>
              )}

            </div>

          )
        ) : null}
      </div>
      {debug(citiesById)}
    </div >
  );
}
export default AdminPickupModalOccurrences;
