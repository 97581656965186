import { useEffect } from 'react';
import Header from './../components/common/Header'
import './defaultLayout.css';
const DefaultLayout = ({ children }) => {
  useEffect(() => {
    document.body.classList.add('default-layout');
    return () => {
      document.body.classList.remove('default-layout');
    };
  }, []);
  return (
    <div>
      <div id="default-layout">
        {false && <Header />}
        <div className='container0'>
          <div className='content'>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
export default DefaultLayout;