// OrdersList.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { debug, formatPrice, formatDate } from './../../../utils'
import { BiShow, BiHide } from "react-icons/bi";
import API from './../../../api';
// import { useNavigate, Navigate } from "react-router-dom";
// import { useDispatch, useSelector } from 'react-redux';
// import UserSignIn from './../user/UserSignIn'
// import './order.css';
import { GrCaretPrevious } from "react-icons/gr";
function AdminOrdersList({ orders, onSelect }) {
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  // const navigate = useNavigate();
  // const cart = useSelector((state) => state.cart);
  // const user = useSelector((state) => state.user);
  return (
    <div id="orders-list">
      <table className="table table-sm table-striped small text-center" >
        <thead>
          <tr className="">
            <th>Date</th>
            <th>Numéro</th>
            <th>TTC</th>
            <th>Paiement</th>
            <th>Solde</th>
            <th>Statut</th>
            <th style={{ with: '25px' }}></th>
          </tr>
        </thead>
        <tbody>
          {orders.map(order => (
            <tr key={order.ordId}>
              <td>{formatDate(order.ordCreatedAt)}</td>
              <td>{order.ordNumber}</td>
              <td>{formatPrice(order.ordToBePaid)} €</td>
              <td>{formatPrice(order.ordPaid)} €</td>
              <td>{formatPrice(order.ordBalance)} €</td>
              <td>
                {order.ordPaymentStatus === 'paid' && <span className="text-success">Réglée</span>}
                {order.ordPaymentStatus === 'partial' && <span className="text-success">Rgl partiel</span>}
                {order.ordPaymentStatus === '' && <span className="text-danger">Non réglée</span>}
              </td>
              <td className="text-center"><span onClick={e => onSelect(order)}><BiShow /></span></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div >
  );
}
export default AdminOrdersList;

