import axios from 'axios';
const api_url = process.env.REACT_APP_API_URL;

const API = axios.create({
  baseURL: api_url,
  timeout: 30000,
});

API.getUrl = () => {
  return api_url;
}
/*
API.getRequestHeaders = () => {
  const headers = {}
  //adminToken


  const token = localStorage.getItem('token');
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}
*/

API.getRequestHeaders = (route) => {


  const headers = {};
  // Tente de récupérer le token d'authentification stocké
  let token = null
  if (!route.startsWith('/admin')) {
    token = localStorage.getItem('token');
  } else {
    token = localStorage.getItem('adminToken');
  }
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}



API.interceptors.request.use(
  (config) => {
    config.headers = { ...config.headers, ...API.getRequestHeaders(config.url) };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Gérer l'erreur ici
    console.error('Une erreur s\'est produite:', error);
    // Retourner une promesse rejetée pour propager l'erreur
    return Promise.reject(error);
  }
);

export default API;
