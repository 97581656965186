// AdminCustomerProfile.js
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { debug, formatDate } from './../../../utils'
import AdminCustomerProfile from './AdminCustomerProfile';
import AdminCustomerOrders from './AdminCustomerOrders';
import API from './../../../api'
import './admin-customer.css';
const AdminCustomer = ({ customer, onSaved }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const config = useSelector((state) => state.config);
  const [orders, setOrders] = useState([]);
  const [activeTab, setActiveTab] = useState('profile'); // || occurrences
  return (
    <div id="AdminCustomer">
      <ul className='nav nav-tabs'>
        <li className='nav-item'><a className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`} onClick={e => setActiveTab('profile')} >Profil<span className='required'></span></a></li>
        {customer.custId !== '' &&
          <li className='nav-item'><a className={`nav-link ${activeTab === 'orders' ? 'active' : ''}`} onClick={e => setActiveTab('orders')}>Commandes<span className='required'></span></a></li>
        }
        <li className='nav-item ms-auto'>
        </li>
      </ul>
      <div className=' tab-contents'>
        {activeTab === 'profile' && <AdminCustomerProfile customer={customer} onSaved={onSaved} />}
        {activeTab === 'orders' && <AdminCustomerOrders customer={customer} />}
      </div>
    </div>
  );
};
export default AdminCustomer;

/*

*/