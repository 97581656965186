// OrderDetails.js
import React, { useState, useEffect } from 'react';
import { debug, ucFirst, formatPrice, formatDate, addBusinessDays, swalConfirm, swalAlert, download } from './../../../utils'
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion';
import API from './../../../api';
import CartDetails from './../../cart/CartDetails';
import './admin-order-details.css';
import AdminOrderModalEditItem from './AdminOrderModalEditItem';
import { BiCaretDown, BiCaretUp } from "react-icons/bi";
import { FaEdit, FaPaperclip } from 'react-icons/fa';
import { cloneDeep, isEqual } from 'lodash';
import { setCart } from '../../../redux/cartSlice';
import { getCartCumuls } from '../../cart/cart.utils';
import Cart from './../../cart/Cart';
import Uploader from '../../helpers/uploader/Uploader.js';
import FluxComponent from './FluxComponent.js';
function OrderDetails({ orderId, onUpdated }) {
  const config = useSelector((state) => state.config);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const [order, setOrder] = useState(null);
  const [editedItem, setEditedItem] = useState(null);
  const [formIsInvadid, setFormIsInvalid] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  useEffect(() => {
    if (order) {
      try {
        setFormIsInvalid(order.items[0].error == true || order.items[1].error == true);
      } catch (e) {
        swalAlert('Cette commande ne peut être affichée.');
      }
    }
  }, [order]);
  useEffect(() => {
    get_order();
  }, [orderId]);
  const get_order = async () => {
    let response;
    try {
      response = await API.get('/admin/order/' + orderId + '?include=customer');
      setOrder(response.data);
    } catch (e) {
    }
  }
  const sendPaymentRequest = async () => {
    try {
      const res = await swalConfirm('Confirmez-vous le renvoi de la demande&nbsp;de&nbsp;paiement&nbsp;?');
      if (res) {
        try {
          const response = await API.post('/admin/order/' + orderId + '/payment_request');
          swalAlert('La demande de paiement a été envoyée');
          get_order();
        } catch (error) {
          swalAlert(error.response.data.error.message)
        }
      }
    } catch (e) {
    }
  }
  const handleItemEdit = async (item) => {
    setEditedItem(item);
  }
  const handleItemChange = (item) => {
    if (!isEqual(item, editedItem)) {
      const orderCopy = cloneDeep(order);
      orderCopy.items.map(oi => oi.error = false);
      const index = orderCopy.items.findIndex(it => it.oiId == item.oiId);
      orderCopy.items[index] = { ...item, error: false };
      if (item.oiFlow == 'collecte') {
        orderCopy.ordNearestRestitutionDate = addBusinessDays(item.occurrence_city.pocDate, config.minimalGapBetweenCollectionAndRestitution);
        if (
          orderCopy.items[1].oiPickupOccurrenceCityId != 'poc0000000000000'
          && orderCopy.items[1].occurrence_city.pocDate < formatDate(orderCopy.ordNearestRestitutionDate, 'Y-m-d')
        ) {
          orderCopy.items[1].error = true;
        }
      }
      if (item.oiFlow == 'restitution') {
        const date = formatDate(addBusinessDays(item.occurrence_city.pocDate, -1 * config.minimalGapBetweenCollectionAndRestitution), 'Y-m-d');
        if (
          orderCopy.items[0].occurrence_city.pocDate > date) {
          orderCopy.items[0].error = true;
        }
      }
      setOrder({ ...orderCopy });
      setFormIsDirty(true)
    }
    setEditedItem(null);
  }
  const handleCartUpdated = (articles) => {
    const cumuls = getCartCumuls(articles);
    const ordCart = cloneDeep({ ...order.ordCart, articles: articles, ...cumuls });
    console.log(ordCart.itemsCounts.all)
    setOrder({ ...order, ordCart: ordCart });
    setFormIsDirty(true);
  }
  const handleSave = async () => {
    try {
      const response = await API.put('/admin/order/' + order.ordId, order);
      setOrder({ ...order, ...response.data });
      console.log(typeof onUpdated)
      if (typeof onUpdated == 'function') {
        onUpdated({ ...order, ...response.data })
      }
    } catch (error) {
      swalAlert(error.message)
    }
  }
  const setUposBDC = async (file) => {
    console.log(file);
    try {
      const response = await API.post('/admin/order/' + order.ordId + '/setUposBDC', { ordUposPdf: file.path })
      let message = "<p>Montant à régler lu : " + response.data.ordTotalIT + " EUR. <br/>";
      message += 'Souhaitez-vous envoyer la demande de paiement ?</p>'
      // message += '<p class="small"><span className="pointer d-inline-block mx-2 text-warning" onClick=download("/utils/file?path="' + order.ordUposPdf + ')}>Afficher</span></p>'
      const result = await swalConfirm(message);
      if (result == true) {
        try {
          const response = await API.post('/admin/order/' + orderId + '/payment_request');
          swalAlert('La demande de paiement a été envoyée');
          get_order();
        } catch (e) {
          swalAlert(e.message)
        }
      } else {
        get_order();
      }
    } catch (error) {
      swalAlert(error.response.data.error.message);
    }
  }
  return (
    <div id="order-details">
      {order &&
        <div className="position-relative">
          <div className='d-flex justify-content-end  w-100' style={{ top: '-0px' }}>
            <div>{debug(order.ordFlows, 0)}</div>
            {(order.ordUposPdf !== '' && order.ordBalance < 0) &&
              <button className="btn btn-sm btn-outline-danger my-2 ms-2"
                onClick={sendPaymentRequest}
                disabled={formIsDirty}>
                {
                  order.ordPaymentStatus == '' ? <>Envoyer la demande de paiement</> : <>Renvoyer la demande de paiement</>
                }
              </button>
            }
            <button className="btn btn-sm btn-outline-warning  my-2 mx-2"
              onClick={handleSave}
              disabled={formIsInvadid || !formIsDirty} >Sauvegarder</button>
            <div className='border border-light rounded ms-2 py-1 px-2'>
              <FluxComponent ordFlows={order.ordFlows} />
            </div>
          </div>
          <div className="section order-infos">
            <div className="d-flex justify-content-between align-items-center">
              <label>Commande : </label>
            </div>
            <table className="table table-sm table-bordered fs-7">
              <thead>
                <tr>
                  <th className="text-center">Date</th>
                  <th className="text-center">Numéro</th>
                  <th className="text-center">HT</th>
                  <th className="text-center">Remise</th>
                  <th className="text-center">HT net</th>
                  <th className="text-center">TTC</th>
                  <th className="text-center">Reglé</th>
                  <th className="text-center">Solde</th>
                  <th className="text-center">Paiement</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">
                    {formatDate(order.ordCreatedAt)}
                  </td>
                  <td className="text-center">
                    {order.ordNumber}
                  </td>
                  <td className="text-center">
                    {formatPrice(order.ordTotalET)}
                  </td>
                  <td className="text-center">
                    {formatPrice(order.ordDiscountET)}
                  </td>
                  <td className="text-center">
                    {formatPrice(order.ordTotalETDiscounted)}
                  </td>
                  <td className="text-center">
                    {formatPrice(order.ordToBePaid)}
                  </td>
                  <td className="text-center">
                    {formatPrice(order.ordPaid)}
                  </td>
                  <td className="text-center">
                    {formatPrice(order.ordBalance)}
                  </td>
                  <td className="text-center">
                    {order.ordPaymentStatus === 'paid' && <span className="text-success">Réglée</span>}
                    {order.ordPaymentStatus === 'partial' && <span className="text-warning">Rgl partiel</span>}
                    {order.ordPaymentStatus === 'pending' && <span className="text-danger">En attente</span>}
                    {order.ordPaymentStatus === '' && <span className="">Non envoyé</span>}
                  </td>
                </tr>
              </tbody>
            </table>
            <p className='small'>
              Bon de commande UPOS :&nbsp;
              {order.ordUposPdf != '' &&
                <span className="pointer d-inline-block mx-2 text-warning" onClick={e => download('/utils/file?path=' + order.ordUposPdf)}>Afficher</span>
              }
              <span className='d-inline-block mx-2'>
                <Uploader
                  inputProps={{ accept: 'application/pdf', multipe: 'false' }}
                  onUploadStart={e => { }}
                  onUploadSuccess={file => { setUposBDC(file) }}
                  onUploadFailure={e => { console.log(e) }}
                />
              </span>
              {order.ordUposPdf != '' &&
                <>
                  &bull;
                  N° de cmde UPOS :&nbsp;
                  <span className="pointer d-inline-block mx-2 text-warning">{order.ordUposNumber}</span>
                </>
              }
            </p>
          </div>
          <div className="section d-flex justify-content-between align-items-stretch">
            <div className=' w-33' style={{ flex: '0 0 33%' }}>
              <div className={`border rounded-1 p-2 bg-white h-100`}>
                <label className="flex-grow-1" >Client : </label>
                {order.customer?.custName} {order.customer?.custFirstname}<br />
                <span className='fs-8'>
                  {/* order.customer?.custAddress}<br />
                  {order.customer?.custPC} {order.customer?.custCity}<br /> */}
                  {order.customer?.user?.userPhone} <br />
                  {order.customer?.user?.userEmail}
                </span>
              </div>
            </div>
            {
              order.items.map((item, index) => (
                <div className=' w-33' style={{ flex: '0 0 33%' }}>
                  <div className={`border rounded-1 p-2 bg-white ${item.error ? 'border-danger' : 'border-light'} ${index == 0 ? 'mx-2' : 'ms-2'} `}>
                    <div>
                      <div className="d-flex flex-row">
                        <label className="flex-grow-1" >{ucFirst(item.oiFlow)} : </label>
                        <div>
                          <span className="pointer d-inline-block me-2" onClick={e => handleItemEdit(item)}><FaEdit /></span>
                        </div>
                      </div>
                      <div className="fs-7">
                        {item.oiPickupOccurrenceCityId != 'poc0000000000000' ? (
                          <>
                            {item.partner && <span>{item.partner.partName}<br /></span>}
                            {item.address.adrText}<br />
                            {item.city.citPC} {item.city.citPublicName}
                            {item.city.citPublicName != item.city.citName && <><br /><em>{item.city.citName}</em></>}
                            <br />
                            {ucFirst(formatDate(item.occurrence_city.pocDate, 'textfull'))} / {item.occurrence_city.pocSlotStart}-{item.occurrence_city.pocSlotEnd}
                          </>
                        ) : (<>
                          Retrait la repasserie<br />
                          A compter du {ucFirst(formatDate(order.ordNearestRestitutionDate, 'textfull'))}
                        </>
                        )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
          {order.ordArticles &&
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header className='p-0 m-0' >Articles</Accordion.Header>
                <Accordion.Body>
                  <div className="section fs-7" style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                    <CartDetails cart={order.ordCart} disabled={false} onUpdate={handleCartUpdated} />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          }
        </div>}
      {debug(order, 0)}
      {editedItem && <AdminOrderModalEditItem onChange={handleItemChange} onHide={e => setEditedItem(null)} item={editedItem} />}
    </div >
  );
}
export default OrderDetails;
