import React, { useEffect, useState, useRef } from 'react';
import { debug, formatPrice, swalAlert, isDev } from '../../../utils.js';
import { FaPlus, FaTimes, FaRegTrashAlt } from 'react-icons/fa';
import cloneDeep from 'lodash/cloneDeep';
import Switcher from '../../helpers/Switcher/Switcher.js';
import Modal from 'react-bootstrap/Modal';
import Uploader from '../../helpers/uploader/Uploader.js';
import Picture from '../../helpers/Picture/Picture.js';
import './admin-catalog.css';
import './../../../css/forms.css';
const AdminCatalogModalProduct = ({ product, categories, accessoires, options, onSave }) => {
  const [modalProduct, setModalProduct] = useState(null);
  useEffect(() => {
    setModalProduct(cloneDeep(product))
  }, [product]);
  const setProdPriceRow = (idx, event) => {
    const pricesList = [...modalProduct.prodPricesList];
    if (!pricesList[idx]) {
      pricesList[idx] = { q: 0, p: 0 };
    }
    pricesList[idx][event.target.name] = event.target.value;
    const pricesListFiltered = pricesList.filter(it => it.q || it.p);
    setModalProduct({ ...modalProduct, prodPricesList: pricesListFiltered });
  }
  return (
    <Modal show={true} onHide={e => onSave(null)}
      size={`${modalProduct ? 'lg' : 'mg'}`}
      keyboard={false}
      backdrop='static' >
      <Modal.Header closeButton>
        <div className='row flex-grow-1' >
          <div className='col-sm-12'>
            <Modal.Title>
              {(modalProduct?.prodId) ? 'Modifier ' : ' Ajouter '} un produit
            </Modal.Title>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        {modalProduct &&
          <form className='row'>
            <div className='col-7 pe-5'>
              <div className='form-group'>
                <label className='form-group-label' required>Catégorie : </label>
                <select className='form-control form-control-sm' value={modalProduct.prodCatId} onChange={e => setModalProduct({ ...modalProduct, prodCatId: e.target.value })} >
                  <option value=''>&raquo; Sélectionner</option>
                  <option key={'cat5e34255b9053'} value='cat5e34255b9053'>Pressing</option>
                  {categories.filter(c => c.catParentId == 'cat5e34255b9053').map((category, index) => (
                    <option key={index} value={category.catId}>&bull; {category.catName}</option>
                  ))}
                  <option key={'cat5e34255b9052'} value='cat5e34255b9052'>Repassage</option>
                  {categories.filter(c => c.catParentId == 'cat5e34255b9052').map((category, index) => (
                    <option key={index} value={category.catId}>&bull; {category.catName}</option>
                  ))}
                </select>
              </div>
              <div className='form-group'>
                <label className='form-group-label' required>Désignation : </label>
                <input className='form-control form-control-sm' type='text' value={modalProduct.prodDesignation} onChange={e => setModalProduct({ ...modalProduct, prodDesignation: e.target.value })} />
              </div>
              <div className='form-group'>
                <label className='form-group-label'>Note : </label>
                <input className='form-control form-control-sm' type='text' value={modalProduct.prodMemo} onChange={e => setModalProduct({ ...modalProduct, prodMemo: e.target.value })} />
              </div>
              <div className='row'>
                <div className='col-sm-4'>
                  <div className='form-group'>
                    <label className='form-group-label'>Référence : </label>
                    <input className='form-control form-control-sm' type='text' value={modalProduct.prodRef} onChange={e => setModalProduct({ ...modalProduct, prodRef: e.target.value })} />
                  </div>
                </div>
                {false && <div className='col-sm-4 '>
                  <div className='form-group'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <label className='form-group-label'>Prix : </label>
                    </div>
                    <input className='form-control form-control-sm' type='number' value={modalProduct.prodUnitPrice} onChange={e => setModalProduct({ ...modalProduct, prodUnitPrice: e.target.value })} />
                  </div>
                </div>}
              </div>
              <div className='form-group'>
                <label className='form-group-label me-3' required>Options : </label><br />
                <div className='border border-light py-1 px-2 rounded-2 '>
                  {options.map((option, index) => (
                    <label key={option.optId} className='checkbox-label me-3'>
                      <input
                        type='checkbox'
                        value={option.optId}
                        checked={modalProduct.prodOptions.includes(option.optId)}
                        onChange={e => {
                          let updatedOptions;
                          if (e.target.checked) {
                            updatedOptions = [...modalProduct.prodOptions, option.optId];
                          } else {
                            updatedOptions = modalProduct.prodOptions.filter(id => id !== option.optId);
                          }
                          setModalProduct({ ...modalProduct, prodOptions: updatedOptions });
                        }}
                      />
                      {option.optName}
                    </label>
                  ))}
                </div>
              </div>
              {accessoires.length > 0 &&
                <>
                  {accessoires[0].prodCatId !== modalProduct.prodCatId && <div className='form-group'>
                    <label className='form-group-label me-3' required>Accessoires obligatoires à la premmière commande : </label><br />
                    <div className='border border-light py-1 px-2 rounded-2 '>
                      {accessoires.map((accessoire, index) => (
                        <label key={accessoire.prodId} className='checkbox-label me-3'>
                          <input
                            type='checkbox'
                            value={accessoire.prodId}
                            checked={modalProduct.prodAccessoires.includes(accessoire.prodId)}
                            onChange={e => {
                              let updatedAccessoires;
                              if (e.target.checked) {
                                updatedAccessoires = [...modalProduct.prodAccessoires, accessoire.prodId];
                              } else {
                                updatedAccessoires = modalProduct.prodAccessoires.filter(id => id !== accessoire.prodId);
                              }
                              setModalProduct({ ...modalProduct, prodAccessoires: updatedAccessoires });
                            }}
                          />
                          {accessoire.prodDesignation}
                        </label>
                      ))}
                    </div>
                  </div>}
                </>}
              <div className='row'>
                <div className='col-6 text-start'>
                  <div className='form-group  d-flex align-items-center justify-content-start'>
                    <label className='form-group-label me-3 pt-1' required>Affiché : </label>
                    <Switcher onValue="active" offValue="inactive" name='prodStatus' value={modalProduct.prodStatus} className='' onClassName='' offClassName=''
                      onChange={e => setModalProduct({ ...modalProduct, prodStatus: e.target.value })} />
                  </div>
                </div>
                <div className='col-6 text-end'>
                  <div className='form-group'>
                    {modalProduct.prodId && <button type="button" className='btn btn-sm btn-outline-secondary'
                      onClick={e => { setModalProduct({ ...modalProduct, prodStatus: 'archived' }); }}>
                      <FaRegTrashAlt />
                    </button>
                    }
                  </div>
                </div>
              </div>
              <div className='form-group pt-5'>
                <button type="button" className='btn btn-outline-warning' onClick={e => onSave(modalProduct)}
                >Sauvegarder</button>
              </div>
              {modalProduct.prodStatus == 'archived' && <p className='small text-red pt-2'>Ce produit va être supprimé.</p>}
            </div>
            <div className='col-5 ps-5'>

              <div className='form-group'>
                <label className='form-group-label' required>Flux : </label>
                <select
                  className='form-control form-control-sm'
                  value={modalProduct.prodFlow}
                  onChange={e => setModalProduct({ ...modalProduct, prodFlow: e.target.value })} >
                  <option value=''>&raquo; Sélectionner</option>
                  <option value='pressing'>Pressing</option>
                  <option value='RNA'>RNA</option>
                  <option value='RTE'>RTE</option>
                </select>
              </div>

              <div className='form-group'>
                <label className='form-group-label' required>Facturation : </label>
                <select
                  className='form-control form-control-sm'
                  value={modalProduct.prodUnity}
                  onChange={e => setModalProduct({ ...modalProduct, prodUnity: e.target.value })} >
                  <option value=''>&raquo; Sélectionner</option>
                  <option value='piece'>A la pièce</option>
                  <option value='kilo'>Au kilo</option>
                </select>
              </div>
              <div className='form-group'>
                <div className='d-flex justify-content-between mb-1'>
                  <label className='form-group-label sw-bold' required>Grille tarifaire :</label>
                  <input
                    type='checkbox'
                    value={modalProduct.prodShowPrice}
                    checked={modalProduct.prodShowPrice}
                    onChange={e => setModalProduct({ ...modalProduct, prodShowPrice: !modalProduct.prodShowPrice })} />
                </div>
                <div className='d-flex input-group'>
                  <label className='form-group-label w-50 text-center' >Qté min.</label>
                  <label className='form-group-label w-50 text-center' >Prix</label>
                </div>
                {Array.from({ length: 5 }, (_, i) => i).map((idx) => (
                  <div className='d-flex input-group' key={idx}>
                    <input
                      type='number'
                      name="q"
                      className='form-control form-control-sm w-50 text-center'
                      value={modalProduct.prodPricesList[idx]?.q}
                      onChange={e => setProdPriceRow(idx, e)}
                    />
                    <input
                      type='number'
                      name="p"
                      className='form-control form-control-sm w-50 text-center'
                      value={modalProduct.prodPricesList[idx]?.p}
                      onChange={e => setProdPriceRow(idx, e)}
                    />
                  </div>
                ))}
              </div>
              <div className='row'>
                <div className='col'>
                  <div className='form-group'>
                    <label className='form-group-label' required>Photo : </label>
                    {modalProduct.prodCover && (
                      <div>
                        <Picture src={modalProduct.prodCover} height={200} />
                        <div className='py-0'>
                          <span className='d-inline-block pe-2 pointer' onClick={e => setModalProduct({ ...modalProduct, prodCover: '' })}>
                            <FaTimes />
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-sm-3'>
                  <Uploader inputProps={{ accept: 'image/jpeg,image/webp', multipe: 'false' }} onUploadSuccess={upload => setModalProduct({ ...modalProduct, prodCover: upload.path })} />
                </div>
              </div>
            </div>
            <div className='row mt-5 mb-5'>
            </div>
            {debug(modalProduct)}
          </form>
        }
      </Modal.Body>
      <Modal.Footer>
        <div className='text-left flex-grow-1'>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
export default AdminCatalogModalProduct;
/*
*/