// utilsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  overall: false,
  // Autres propriétés d'état...
};

const utilsSlice = createSlice({
  name: 'utils',
  initialState,
  reducers: {
    setState(state, action) {
      Object.keys(action.payload).forEach(key => {
        if (state.hasOwnProperty(key)) {
          state[key] = action.payload[key];
        }
      });
    },
  },
});

export const { setState } = utilsSlice.actions;
export default utilsSlice.reducer;
