// src/components/homes/HomeDefault.js
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './homes.css';
const HomeDefault = () => {
  const user = useSelector((state) => state.user);
  return (
    <>
      <Navigate to="/fr/order" />
    </>
  )
};
export default HomeDefault;
