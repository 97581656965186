// AdminCustomerProfile.js
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { debug, formatDate } from './../../../utils'
import { FaTimes } from "react-icons/fa";
import { AiOutlineArrowLeft } from "react-icons/ai";
import API from './../../../api';
import AdminOrdersList from './../../orders/admin/AdminOrdersList';
import AdminOrderDetails from '../../orders/admin/AdminOrderDetails';
const AdminCustomerOrders = ({ customer, onSaved }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const config = useSelector((state) => state.config);
  const [orders, setOrders] = useState([]);
  const [seletedOrder, setSeletedOrder] = useState(null);
  useEffect(() => {
    if (customer.custId != '') {
      get_customer_orders();
    }

  }, [customer]);
  const get_customer_orders = async () => {
    try {
      const query = { 'ordUserId:eq': customer.custUserId }
      const response = await API.get('/admin/orders?' + 'query=' + JSON.stringify(query))
      setOrders(response.data);
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <div id="AdminCustomerOrders" className="overflow-hidden" >
      <div className="slider d-flex flex-wrap" style={{ marginLeft: `${seletedOrder ? '-100%' : '0'}` }}>
        <div className="w-50">
          {/*  <button type="button" onClick={get_customer_orders}>Orders!</button> */}
          <AdminOrdersList orders={orders} onSelect={order => setSeletedOrder(order)} />
        </div>
        <div className="w-50">
          <div className="text-start mb-3" >
            <span className="small fw-bold pointer" onClick={e => setSeletedOrder(null)}><AiOutlineArrowLeft /> Retour aux commandes</span>
          </div>
          {seletedOrder &&
            <AdminOrderDetails orderId={seletedOrder?.ordId}></AdminOrderDetails>
          }
        </div>
      </div >
    </div >
  );
};
export default AdminCustomerOrders;