import React, { useEffect, useState, useRef } from 'react';
import { debug, formatPrice, swalAlert, isDev } from '../../../utils.js';
import cloneDeep from 'lodash/cloneDeep';
import Switcher from '../../helpers/Switcher/Switcher.js';
import Modal from 'react-bootstrap/Modal';
import './admin-catalog.css';
import './../../../css/forms.css';
const AdminCatalogModalOption = ({ option, onSave }) => {

  const [modalOption, setModalOption] = useState(null);
  useEffect(() => {
    setModalOption(cloneDeep(option))
  }, [option]);

  return (
    <Modal show={true} onHide={e => { onSave(null); }}
      size='md'
      keyboard={false}
      backdrop='static' >
      <Modal.Header closeButton>
        <div className='row flex-grow-1' >
          <div className='col-sm-12'>
            <Modal.Title>
              {modalOption?.optId ? 'Modifier ' : ' Ajouter '} une option
            </Modal.Title>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>

        {modalOption && <form>
          <div className='form-group'>
            <label className='form-group-label' required>Nom : </label>
            <input className='form-control form-control-sm' name='optName' type='text' value={modalOption.optName} onChange={e => setModalOption({ ...modalOption, optName: e.target.value })} />
          </div>
          <div className='form-group'>
            <label className='form-group-label' required>Prix : </label>
            <input className='form-control form-control-sm' name='optPrice' type='text' value={modalOption.optPrice} onChange={e => setModalOption({ ...modalOption, optPrice: e.target.value })} />
          </div>
          <div className='col-sm-6'>
            <label className='form-group-label' required>Activée : </label>
            <div className='pt-2'>
              <Switcher onValue="active" offValue="inactive" name='optStatus' value={modalOption.optStatus} className='' onClassName='' offClassName=''
                onChange={e => setModalOption({ ...modalOption, optStatus: e.target.value })} />
            </div>
          </div>
          <div className='form-group pt-3'>
            <button type="button" className='btn btn-outline-warning' onClick={e => { onSave(modalOption); }} >Sauvegarder</button>
          </div>
        </form>}
      </Modal.Body>
      <Modal.Footer>
        <div className='text-left flex-grow-1'>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
export default AdminCatalogModalOption