import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Picture from '../helpers/Picture/Picture';
import { debug, formatPrice } from '../../utils';
import { cloneDeep } from 'lodash';
import { getUnitPrice } from './../cart/priceUtils';
import { AiOutlineArrowLeft } from "react-icons/ai";
import './order-products-list.css'
import InputQuantity from '../helpers/InputQuantity/InputQuantity';
const OrderProductsList = ({ onAdd, onHide, catalog }) => {
  const [modalArticles, setModalArticles] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [topCategories, setTopCategories] = useState([]);
  const [itemsCount, setItemsCount] = useState(0);
  useEffect(() => {
    setTopCategories(catalog.categories.filter(c => c.catParentId == ''));
  },
    [catalog]
  );
  const handleQuantityChange = (quantity, prodId) => {
    const product = catalog.products.find(p => p.prodId === prodId);
    const updatedArticles = cloneDeep(modalArticles);
    if (!updatedArticles[prodId]) {
      updatedArticles[prodId] = { ...newArticle(prodId) };
    }
    const optionsPriceTotal = Object.values(updatedArticles[prodId].options).reduce((acc, option) => acc + parseFloat(option.optPrice), 0);
    updatedArticles[prodId].quantity = quantity;
    updatedArticles[prodId].unitPrice = getUnitPrice(product, updatedArticles[prodId].quantity);
    if (product.prodUnity == 'piece') {
      updatedArticles[prodId].totalPrice = ((updatedArticles[prodId].unitPrice + optionsPriceTotal) * updatedArticles[prodId].quantity).toFixed(2);
    }
    setModalArticles({ ...updatedArticles })
  }
  useEffect(() => {
    let itemCountCopy = 0;
    Object.values(modalArticles).forEach(article => {
      itemCountCopy += article.quantity;
    });
    setItemsCount(itemCountCopy);
  }, [modalArticles]);
  const toggleOption = (optId, prodId) => {
    const product = catalog.products.find(p => p.prodId === prodId);
    const option = catalog.options.find(o => o.optId === optId);
    const updatedArticles = modalArticles;
    if (!updatedArticles[prodId]) {
      updatedArticles[prodId] = { ...newArticle(prodId) }
    }
    if (!Object.keys(updatedArticles[prodId].options).includes(optId)) {
      updatedArticles[prodId].options[optId] = option;
    } else {
      delete (updatedArticles[prodId].options[optId])
    }
    const optionsPriceTotal = Object.values(updatedArticles[prodId].options).reduce((acc, option) => acc + parseFloat(option.optPrice), 0);
    updatedArticles[prodId].unitPrice = getUnitPrice(product, updatedArticles[prodId].quantity);
    if (product.prodUnity == 'piece') {
      updatedArticles[prodId].totalPrice = ((updatedArticles[prodId].unitPrice + optionsPriceTotal) * updatedArticles[prodId].quantity).toFixed(2);
    }
    setModalArticles({ ...updatedArticles })
    setModalArticles({ ...updatedArticles })
  }
  const newArticle = (prodId) => {
    const product = catalog.products.find(p => p.prodId == prodId);
    return {
      artId: new Date().getTime(),
      parentId: selectedCategory ? selectedCategory.catId : product.prodCatId,
      unity: product.prodUnity,
      prodId: prodId,
      quantity: 0,
      options: {},
      unitPrice: 0,
      totalPrice: 0,
    }
  }
  useEffect(() => {
  }, [modalArticles])
  return (
    <Modal
      show={true}
      onHide={onHide}
      centered={true}
      scrollable={true}
      className="modal-order-products-list" keyboard={true}
      id="OrderProductList">
      <Modal.Header closeButton>
        <span className='pointer' onClick={e => setSelectedCategory(null)} style={{ opacity: selectedCategory === null ? 0 : 1 }}>
          <AiOutlineArrowLeft className="me-2 position-relative" style={{ top: '-2px' }} />
          Retour aux catégories
        </span>
      </Modal.Header>
      {catalog &&
        <Modal.Body>
          <div className='wrapper' >
            <div className='slide' style={{ marginLeft: selectedCategory === null ? 0 : '-100%' }}>
              {topCategories.map(topCategory => (
                <div key={topCategory.catId}>
                  <h5 className="cat-name border py-2 text-center mb-3">{topCategory.catName}</h5>
                  <ul className='subcategories catalog-items mb-4' >
                    {catalog.categories
                      .filter(c => c.catParentId === topCategory.catId)
                      .map(category => (
                        <li key={category.catId} onClick={e => setSelectedCategory(category)} className='pointer catalog-item d-flex flex-column justify-content-start'>
                          <div className='cover'>
                            {category.catCover != '' &&
                              <Picture src={category.catCover} size="maxw=150&maxh=150" />
                            }
                          </div>
                          <h6 className='subcat-name mt-1 mb-0'>{category.catName}</h6>
                        </li>
                      ))}
                    {catalog.products
                      .filter(p => p.prodCatId === topCategory.catId)
                      .map(product => (
                        <li key={product.prodId} className='catalog-item d-flex flex-column justify-content-between'>
                          <div>
                            <div className='cover'>
                              {product.prodCover != '' &&
                                <Picture src={product.prodCover} size="maxw=150&maxh=150" />
                              }
                            </div>
                            <h6 className='subcat-name mt-1 mb-0'>{product.prodDesignation}</h6>
                          </div>
                          {(product.prodShowPrice == true && Number(product.prodPricesList[0].p) > 0) &&
                            <div className=''>{modalArticles[product.prodId] ? modalArticles[product.prodId].unitPrice : product.prodPricesList[0].p} € / {product.prodUnity} </div>
                          }
                          {product.prodMemo !== '' &&
                            <div className='small'>{product.prodMemo}</div>
                          }
                          <div className='text-center mt-1'>
                            <label className='checkbox-label small pe-0 '>
                              <input type='checkbox'
                                style={{ top: 1 }}
                                checked={modalArticles[product.prodId]?.quantity > 0}
                                onChange={e => {
                                  const q = (modalArticles[product.prodId]?.quantity > 0) ? 0 : 1
                                  handleQuantityChange(q, product.prodId);
                                }
                                }
                              />Ajouter au panier</label>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
            </div>
            <div className='slide'>
              {(selectedCategory?.catMemo != '') &&
                <div className='cat-memo mb-4 fw-bold' >{selectedCategory?.catMemo}</div>
              }
              <ul className='products catalog-items'>
                {catalog.products
                  .filter(p => p.prodCatId === selectedCategory?.catId)
                  .map((product, index) => (
                    <li key={index} onClick={e => { }} className='pointer d-flex catalog-item'>
                      <div className='me-2 flex-grow-0'>
                        <div className='cover'>
                          {product.prodCover != '' &&
                            <Picture src={product.prodCover} size="maxw=150&maxh=150" />
                          }
                        </div>
                      </div>
                      <div className='text-start ps-2 flex-grow-1 d-flex flex-column justify-content-between' >
                        <div className='top d-flex justify-content-between align-items-start' >
                          <h6 className='m-0 py-0 text-start' >{product.prodDesignation}</h6>
                          {false && <div className='price ps-3 text-nowrap'>
                            {product.prodUnity === 'piece' && (<></>)}
                          </div>}
                        </div>
                        {(product.prodShowPrice == true && Number(product.prodPricesList[0].p) > 0) &&
                          <div className=''>{
                            formatPrice(modalArticles[product.prodId] ? modalArticles[product.prodId].unitPrice : product.prodPricesList[0].p)
                          } € / {product.prodUnity} </div>
                        }
                        {product.prodMemo !== '' &&
                          <div>{product.prodMemo}</div>
                        }
                        <div className='form-inline text-center input-group input-group-sm mb-0'>
                          <InputQuantity
                            disabled={false}
                            min={0}
                            onUpdate={q => handleQuantityChange(q, product.prodId)}
                            quantity={modalArticles[product.prodId] ? modalArticles[product.prodId].quantity : 0}
                          />
                        </div>
                        {product.prodOptions.length > 0 &&
                          <ul className='text-start mt-1'>
                            {product.prodOptions.map(optId => (
                              <li>
                                <label>
                                  <input type="checkbox" className='me-2'
                                    checked={modalArticles[product.prodId]?.options && Object.keys(modalArticles[product.prodId].options).includes(optId)}
                                    disabled={!modalArticles[product.prodId] || modalArticles[product.prodId]?.quantity == 0}
                                    onChange={e => toggleOption(optId, product.prodId)}
                                  />
                                  {catalog.options.find(o => o.optId === optId).optName} ({catalog.options.find(o => o.optId === optId).optPrice} €)
                                </label>
                              </li>
                            )
                            )}
                          </ul>}
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div >
        </Modal.Body >
      }
      <Modal.Footer className="d-block">
        <div className=' text-center'>
          <button className='btn btn-outline-blue' onClick={e => onAdd(Object.values(modalArticles))} disabled={itemsCount == 0}>
            Ajouter {itemsCount <= 1 ? 'cet article' : 'ces articles'} au panier
          </button>
        </div>
      </Modal.Footer>
    </Modal >
  )
}
export default OrderProductsList;
