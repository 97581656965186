// Header_User.js
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BiCart } from "react-icons/bi";
import './header-cart.css';

function Header_Orders() {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  return (
    <div className="header-cart">
      {cart.itemsCounts?.all > 0 ? (
        <Link className='cart badge-wrapper' to="/order/confirm">
          <BiCart size="40" />
          <span className='badge bg-danger'>{cart.itemsCounts?.all}</span>
        </Link>
      ) : (
        <BiCart size="40" />
      )}
    </div>
  );
}
export default Header_Orders;
