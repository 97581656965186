import { useEffect, useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import API from './../../../api';
import { Alert } from 'react-bootstrap';
import ProgressBar from '../progressBar/ProgressBar';
import { BsPlusCircleDotted } from 'react-icons/bs';
// import { v4 as uuidv4 } from 'uuid';
import './uploader.css';
import { } from 'react-bootstrap';
const Uploader = ({ inputProps = {}, rootProps = {}, size = 'small', onUploadStart = null, onUploadSuccess = null, onUploadFailure = null }) => {
  const uploads = {};
  const [currentFiles, setCurrentFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [progressBarProgress, setProgressBarProgress] = useState(0);
  const dropzoneRef = useRef(null);


  useEffect(() => {
    console.log(rejectedFiles);
  }, [rejectedFiles]);

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    setRejectedFiles(rejectedFiles);
    try {
      setCurrentFiles([...currentFiles, ...acceptedFiles]);
      acceptedFiles.forEach((file) => {
        file.upload_id = new Date().getTime();
        uploads[file.upload_id] = {
          size: file.size,
          loaded: 0
        }
        if (onUploadStart) {
          onUploadStart(file);
        }
        const formData = new FormData();
        formData.append('file', file);
        API.post('/admin/upload', formData, {
          onUploadProgress: ({ loaded, total }) => {
            if (uploads[file.upload_id]) {
              uploads[file.upload_id].loaded = loaded;
              setProgressBarProgress(calculateTotalProgress())
            }
          },
        })
          .then((response) => {
            delete uploads[file.upload_id];
            if (onUploadSuccess) {
              onUploadSuccess({ ...file, ...response.data });
            }
          })
          .catch((error) => {
            if (onUploadFailure) {
              onUploadFailure(file);
            }
            console.error('Erreur lors de l\'envoi :', error);
          });
      });
    } finally {
    }
  };
  const calculateTotalProgress = () => {
    let totalSize = 0;
    let totalLoaded = 0;
    for (const k in uploads) {
      totalSize += uploads[k].size;
      totalLoaded += uploads[k].loaded;
    }
    return 100 * totalLoaded / totalSize;
  };
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    maxSize: 10 * 1024 * 1024,
    noClick: true,
    ...inputProps,
  });
  const getCombinedInputProps = () => {
    return { ...getInputProps(), ...inputProps, multiple: inputProps.multiple }
  }
  return (
    <div className='uploader' tabIndex={-1}>
      <div {...getRootProps()} className={`drop-zone ${size}`} >
        <input {...getCombinedInputProps()} />
        {size === 'large' ? (
          isDragActive ? (
            <div>Déposez les fichiers ici...</div>
          ) : (
            <div>
              <p>Glissez-déposez des fichiers ici <br /><small>(PDF ou JPG &bull; 10Mo max par fichier )</small></p>
              <p><button type="button" onClick={open} className='btn-upload'><BsPlusCircleDotted /></button></p>
            </div>
          )
        ) : size === 'small' ? (
          <button className='btn-upload' type="button" onClick={open}  ><BsPlusCircleDotted /></button>
        ) : null}
      </div>
      {
        rejectedFiles.length > 0 && (
          <Alert variant="warning" className='text-start mt-3'>
            <button type="button" className="btn-close btn-xs" onClick={() => setRejectedFiles([])}></button>
            <ul className='mb-0 pt-0 pb-0'>
              {rejectedFiles.map((item, index) => (
                <li key={index}>
                  <small>
                    Le fichier "{item.file.path}" est trop volumineux.
                  </small>
                </li>
              ))}
            </ul>
          </Alert>
        )
      }
      {
        progressBarProgress > 0 && progressBarProgress < 100 &&
        <ProgressBar progress={progressBarProgress} />
      }
    </div >
  );
};
export default Uploader;
