import { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import API from '../../api';
import AdminPickupCalendar from './../pickups/admin/AdminPickupCalendar'
function AdminHome() {
  return (
    <><Navigate to='/appAdmin/planning' /></>
  );

}
export default AdminHome;




