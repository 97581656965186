// src/utils/priceUtils.js
export const getUnitPrice = (product, qty = 1) => {
  qty = Math.max(qty, 1);
  const applicablePrices = product.prodPricesList.map(it => ({
    q: Number(it.q),
    p: Number(it.p)
  })).sort((a, b) => a.q - b.q)
    .filter(price => qty >= price.q);
  return applicablePrices[applicablePrices.length - 1].p;
};



