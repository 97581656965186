import React from 'react';
import './badge-counter.css';
function BadgeCounter({ count, className = '', hideOnZero = false, onClick = null }) {
  // Déterminez la classe CSS en fonction du type (collecte ou restitution)
  // const badgeClass = type === 'collecte' ? 'badge bg-secondary' : 'badge bg-danger';

  return (
    <>
      {hideOnZero == false || count > 0 &&
        <span className={`badge badge-counter ${className}`} onClick={onClick}>
          {count}
        </span>
      }
    </>

  );
}

export default BadgeCounter;