import React, { useState } from 'react';
import { BsEye, BsEyeSlash } from 'react-icons/bs';

const InputPassword = ({ onChange, className = '', size = '', inputClassName = '' }) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setPassword(newValue);
    onChange(newValue);
  };

  return (
    <div className={`input-group ${size != '' ? ' input-group-' + size : ''} ${className}`}  >
      <input
        type={showPassword ? 'text' : 'password'}
        className={`form-control ${inputClassName}`}
        placeholder="Mot de passe"
        value={password}
        onChange={handleInputChange}
      />
      <button
        className="btn btn-outline-secondary pt-0"
        type="button"
        onClick={togglePasswordVisibility}
      >
        {showPassword ? <BsEyeSlash /> : <BsEye />}
      </button>
    </div >
  );
};

export default InputPassword;
