import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { debug, removeAccents, swalAlert } from './../../../../utils';
import { redIcon, greyIcon } from './../../../helpers/Leaflet/LeafletIcons';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import TimeSelect from '../../../helpers/TimeSelect/TimeSelect';
import { MdDragHandle, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdManageSearch } from "react-icons/md";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const AdminPickupModalCities = ({ pickup, availableCities, onChange }) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({ citName: '', checked: false });
  const [mapInitialized, setMapInitialized] = useState(false);
  const [selectedCitIds, setSelectedCitIds] = useState([]);
  const [orderWeek, setOrderWeek] = useState('A');
  const filterMatch = (city) => {
    return (
      (removeAccents(city.citName).toLowerCase().startsWith(removeAccents(filters.citName).toLowerCase()))
    )
  }
  useEffect(() => {
    if (pickup.cities) {
      const newSelectedIds = pickup.cities.map(pickupCity => pickupCity.citId);
      setSelectedCitIds(newSelectedIds);
    }
  }, [pickup]);


  useEffect(() => {
    orderCities([...pickup.cities]);
  }, [orderWeek])

  const toggleCity = (city) => {
    const citiesCopy = [...pickup.cities];
    const idx = citiesCopy.findIndex(c => c.citId == city.citId);
    if (idx >= 0) {
      citiesCopy.splice(idx, 1);
      const formattedDate = moment().format('YYYY-MM-DD');
      const foundOccurrences = pickup.occurrences.filter(occurrence => {
        return occurrence.poDate >= formattedDate && occurrence.poStatus === 'active';
      });
      if (foundOccurrences.length > 0) {
        swalAlert("Veuillez vérifier l'impact de cette opération sur les collectes à venir !")
      }
    } else {
      const newCity = {
        ...city,
        pcSlotStartA: pickup.picDefaultSlotStartA,
        pcSlotEndA: pickup.picDefaultSlotEndA,
        pcSlotStartB: pickup.picDefaultSlotStartB,
        pcSlotEndB: pickup.picDefaultSlotEndB,
        pcCityId: city.citId,
        pcOrder: citiesCopy.length + 1
      }

      citiesCopy.push(newCity)

    }
    orderCities(citiesCopy);
  }
  const handleTimeChange = (citId, event) => {
    const newStartTime = event.target.value; // Nouvelle heure de début sélectionnée
    const citiesCopy = pickup.cities.map(city => {
      // TODO:: corriger ici pcSlotStart n'existe plus
      if (city.citId === citId) {
        const payload = {
          pcSlotStart: city.pcSlotStart,
          pcSlotEnd: city.pcSlotEnd,
          [event.target.name]: newStartTime
        }
        if (payload.pcSlotStart >= payload.pcSlotEnd) {
          payload.pcSlotEnd = payload.pcSlotStart;
        }
        return { ...city, ...payload };
      }
      return city;
    });
    orderCities(citiesCopy);
  }
  const orderCities = (cities) => {
    const citiesCopy = [...cities];
    if (orderWeek == 'A') {
      citiesCopy.sort((city1, city2) => {
        const slot1 = `${city1.pcSlotStartA}-${city1.pcSlotEndA}-${city1.pcOrder.toString().padStart(3, '0')}`;
        const slot2 = `${city2.pcSlotStartA}-${city2.pcSlotEndA}-${city1.pcOrder.toString().padStart(3, '0')}`;
        if (slot1 < slot2) return -1;
        if (slot1 > slot2) return 1;
        return 0;
      });
    } else {
      citiesCopy.sort((city1, city2) => {
        const slot1 = `${city1.pcSlotStartB}-${city1.pcSlotEndB}-${city1.pcOrder.toString().padStart(3, '0')}`;
        const slot2 = `${city2.pcSlotStartB}-${city2.pcSlotEndB}-${city1.pcOrder.toString().padStart(3, '0')}`;
        if (slot1 < slot2) return -1;
        if (slot1 > slot2) return 1;
        return 0;
      });
    }
    for (let $order = 0; $order < citiesCopy.length; $order++) {
      citiesCopy[$order].pcOrder = $order;
    }
    onChange({ cities: citiesCopy });
  };
  function SetBounds({ cities }) {

    const map = useMap();
    useEffect(() => {
      if (!mapInitialized) {
        if (cities.length > 0) {
          const bounds = L.latLngBounds(cities.map(city => city.citLatLng.split(/[;,]/)));
          map.fitBounds(bounds);
        }
        setMapInitialized(1);
      }
    }, [cities]);

    return null;
  }
  const handleDragEnd = (data) => {
    const { source, destination, draggableId } = data;
    if (destination && (source.index !== destination.index)) {
      const citiesCopy = [...pickup.cities];
      const draggedCity = citiesCopy.find(city => city.citId === draggableId);
      citiesCopy.splice(source.index, 1);
      citiesCopy.splice(destination.index, 0, draggedCity);
      citiesCopy.forEach((city, index) => {
        city.pcOrder = index; // Index commence à 0, donc on ajoute 1
      });
      orderCities(citiesCopy);
    }
  };

  const position = [49.9222503951, 1.08681168449]; // Coordonnées de départ;
  return (
    <form id="AdminPickupModalCities">
      <div className='row'>
        <div className='col-6'>
          <h6 className='mb-3'>Communes :</h6>
          <div className='input-group mb-2'>
            <span className='input-group-text'><MdManageSearch /></span>
            <input type='text' className='form-control form-control-sm' name='activeFilter' value={filters.citName} onChange={e => { setFilters({ ...filters, citName: e.target.value }) }} />
          </div>
          <div className='table-wrapper' style={{ overflowY: 'scroll', maxHeight: '400px' }}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="pickup-cities">
                {(provided) => (
                  <table className='table table-striped table-sm'>
                    <thead className=''>
                      <tr>
                        <th style={{ width: '15px' }} className=' py-1' ></th>
                        <th style={{ width: '15px' }} className=' py-1'></th>
                        <th className=' py-1'>{orderWeek}</th>
                        <th className='text-center py-1'>
                          <label className={`pointer radio-label ${orderWeek == 'A' ? 'fw-bold' : 'fw-normal'}`} onClick={e => setOrderWeek('A')}>
                            Sem. A
                          </label>
                        </th>
                        <th className='potext-center  py-1'>
                          <label className={`pointer radio-label ${orderWeek == 'B' ? 'fw-bold' : 'fw-normal'}`} onClick={e => setOrderWeek('B')}>
                            Sem. B
                          </label>
                        </th>
                      </tr>
                    </thead>
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {pickup.cities && pickup.cities.map((city, index) => (
                        filterMatch(city) ? (
                          <Draggable key={city.citId} draggableId={city.citId} index={index}>
                            {(provided) => (
                              <tr
                                key={city.citId}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <td className='drag-handle text-center'  {...provided.dragHandleProps}>
                                  <MdDragHandle />
                                </td>
                                <td className='checkbox text-center'>
                                  <span className='pointer' onClick={e => toggleCity(city)}>
                                    <MdOutlineCheckBox />
                                  </span>
                                </td>
                                <td>{city.citName}</td>
                                <td>
                                  <div className={`input-group input-group-sm  alert p-0 m-0 ${city.pcSlotStartA >= city.pcSlotEndA ? 'alert-danger' : null}`}>
                                    <TimeSelect
                                      onChange={event => handleTimeChange(city.citId, event)}
                                      start={pickup.picDefaultSlotStartA}
                                      end={pickup.picDefaultSlotEndA}
                                      step={15}
                                      selectClassName='form-control-sm text-center'
                                      name='pcSlotStartA'
                                      value={city.pcSlotStartA}
                                    />
                                    <TimeSelect
                                      onChange={event => handleTimeChange(city.citId, event)}
                                      start={pickup.picDefaultSlotStartA}
                                      end={pickup.picDefaultSlotEndA}
                                      step={15}
                                      selectClassName='form-control-sm text-center'
                                      name={`pcSlotEndA`}
                                      value={city.pcSlotEndA}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className={`input-group input-group-sm  alert p-0 m-0 ${city.pcSlotStartB >= city.pcSlotEndB ? 'alert-danger' : null}`}>
                                    <TimeSelect
                                      onChange={event => handleTimeChange(city.citId, event)}
                                      start={pickup.picDefaultSlotStartB}
                                      end={pickup.picDefaultSlotEndB}
                                      step={15}
                                      selectClassName='form-control-sm text-center'
                                      name='pcSlotStartB'
                                      value={city.pcSlotStartB}
                                    />

                                    <TimeSelect
                                      onChange={event => handleTimeChange(city.citId, event)}
                                      start={pickup.picDefaultSlotStartB}
                                      end={pickup.picDefaultSlotEndB}
                                      step={15}
                                      selectClassName='form-control-sm text-center'
                                      name={`pcSlotEndB`}
                                      value={city.pcSlotEndB}
                                    />
                                  </div>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ) : null
                      ))}
                      {provided.placeholder}
                    </tbody>
                  </table>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
        <div className='col-6'>
          <MapContainer center={position} zoom={12} style={{ height: '100%', width: '100%' }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution0='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {availableCities.map(city => (
              <Marker key={city.citId} id={city.citId} position={city.citLatLng.split(/[;,]/)} icon={selectedCitIds.includes(city.citId) ? redIcon : greyIcon}
                eventHandlers={{
                  click: (e) => toggleCity(city),
                  mouseover: (e) => {
                    const marker = e.target;
                    if (marker && marker.getPopup()) {
                      marker.openPopup();
                    }
                  },
                  mouseout: (e) => {
                    const marker = e.target;
                    if (marker && marker.getPopup()) {
                      marker.closePopup();
                    }
                  }
                }}
              >
                <Popup>
                  <span className='position-relative me-1 pointer' style={{ top: '-2px' }}>
                    {selectedCitIds.includes(city.citId) ?
                      <MdOutlineCheckBox /> :
                      <MdOutlineCheckBoxOutlineBlank />
                    }
                  </span>
                  {city.citName}
                </Popup>
              </Marker>
            ))}
            <SetBounds cities={availableCities} />
          </MapContainer>
        </div>
      </div>
    </form >
  );
}
export default AdminPickupModalCities;
