// OrdersList.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { debug, formatDate, formatPrice, download } from './../../utils'
import { BiShow, BiHide } from "react-icons/bi";
import { FaRegFilePdf } from 'react-icons/fa';
import { Link } from 'react-router-dom';
function OrdersList({ orders, onSelect }) {
  const { t } = useTranslation();
  return (
    <div id="orders-list">
      {orders &&
        orders.length > 0 ?
        <>
          <table className="table table-sm table-striped small" >
            <thead >
              <tr>
                <th className='text-center ' style={{ width: '120px' }}>Date<br />Numero</th>
                <th className='text-center' style={{ width: '120px' }}>Retrait<br />Retour</th>
                <th className='text-center'>Etat</th>
                <th className='text-center'></th>
                <th className='text-center'></th>

              </tr>
            </thead>
            <tbody>
              {orders?.map(order => (
                <tr key={order.ordId}>
                  <td className='text-center'>
                    {formatDate(order.ordCreatedAt)}
                    <br />
                    {order.ordNumber}
                  </td>
                  <td className='text-center align-middle'>
                    {(() => {
                      const item = order.items.find(oi => oi.oiFlow === 'collecte' && oi.oiPickupId !== '');
                      const poDate = item?.pickup_occurrence.poDate;
                      const formattedDate = poDate ? formatDate(poDate) : 'A la repasserie';
                      return formattedDate;
                    })()}
                    <br />
                    {(() => {
                      const item = order.items.find(oi => oi.oiFlow === 'restitution' && oi.oiPickupId !== '');
                      const poDate = item?.pickup_occurrence.poDate;
                      const formattedDate = poDate ? formatDate(poDate) : 'A la repasserie';
                      return formattedDate;
                    })()}
                  </td>
                  {order.ordStatus != 'pending' ? <>
                    <td className='text-center align-middle'>
                      <>
                        {t(`order-${order.ordStatus}`)}<br />
                      </>
                      {order.ordPaymentStatus == 'pending' &&
                        <><Link className="mt-1 text-danger" to={`/fr/order/${order.ordId}/payment`}>Régler maintenant</Link></>
                      }
                    </td>
                    <td className='text-center  align-middle'>{order.ordStatus != 'pending' ? <>{formatPrice(order.ordToBePaid)} €  </> : '*'}</td>
                  </> : <>
                    <td colSpan={2} className='text-center  align-middle'>{t(`order-${order.ordStatus}`)}</td>
                  </>}
                  <td className="text-end align-middle">
                    {order.ordStatus == 'handled'
                      && <span className='pointer d-inline-block mx-3' onClick={e => download(`/order/${order.ordId}/pdf`)}><FaRegFilePdf size="16" /></span>
                    }

                    <a href={`/fr/customer/orders/${order.ordId}`}><BiShow size="20" /></a>

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </> : <div className='pt-5 mb-5 text-center px-5'>
          <div className='alert alert-primary mx-5'>
            Vous n'avez passé aucune commande.
          </div>
        </div>

      }
      {orders && debug(orders[1], 0)}
    </div >
  );
}
export default OrdersList;
