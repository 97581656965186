import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { formatDate, swalConfirm } from './../../../../utils';
const getStartOfWeek = (dateString) => {
  const date = moment(dateString);
  return date.startOf('week').toDate();
};
const getWeekDays = (startOfWeek) => {
  let days = [];
  for (let i = 0; i < 7; i++) {
    let day = moment(startOfWeek).add(i, 'days');
    if (![6, 0].includes(day.day())) { // 6 = samedi, 0 = dimanche
      days.push(day.toDate());
    }
  }
  return days;
};
const PickupOccurrenceDaySelector = ({ defaultDate, onUpdate }) => {
  const [weekDays, setWeekDays] = useState([]);
  const [value, setValue] = useState([]);
  useEffect(() => {
    setValue(defaultDate)
    const startOfWeek = getStartOfWeek(defaultDate);
    setWeekDays(getWeekDays(startOfWeek));
  }, [defaultDate]);
  const handleDateChange = async (e) => {
    const newDate = e.target.value;
    onUpdate(newDate);
  };
  return (
    <select onChange={handleDateChange} value={value} className='form-control'>
      {weekDays.map((day) => (
        <option key={day.toISOString()} value={formatDate(day, 'Y-m-d')}>
          {formatDate(day, 'text')}
        </option>
      ))}
    </select>
  );
};

export default PickupOccurrenceDaySelector;
