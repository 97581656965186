// AdminCustomersList.js
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { debug, removeAccents } from './../../../utils';
import { FaCheck } from 'react-icons/fa';
import API from './../../../api';
import AdminCustomer from './AdminCustomer';
import { FaSearch } from "react-icons/fa";
import AddressSelector from '../../cities/AddressSelector';
import { MdOpenInNew, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdManageSearch, MdAdd } from "react-icons/md";
import { Modal } from 'react-bootstrap';
const AdminCustomersList = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const config = useSelector((state) => state.config);
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [editedCustomer, setEditedCustomer] = useState(null);
  const get_customers = async () => {
    try {
      const response = await API.get('/admin/customers');
      const customersCopy = response.data.map(c => ({ ...c, searchString: removeAccents(c.custName + ' ' + c.custFirstname).toLowerCase() }));
      const sortedCustomers = [...customersCopy].sort(compareCustomers);
      setCustomers(sortedCustomers);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    get_customers();
  }, []);
  const create_customer = () => {
    setEditedCustomer({
      custId: '',
      user: {},
      orders: []
    })
  }
  useEffect(() => {
    setFilteredCustomers(customers);
  }, [customers]);
  const handleCustomerSaved = (customer) => {
    let customersCopy = [...customers]
    const idx = customers.findIndex(c => c.custId === customer.custId);
    if (idx === -1) {
      customersCopy.push(customer)
      setEditedCustomer(null);
    } else {
      customersCopy = customersCopy.map((c, index) =>
        index === idx ? customer : c
      );
    }
    const sortedCustomers = [...customersCopy].sort(compareCustomers);
    setCustomers(sortedCustomers); // `setCustomers` met à jour l'état avec la liste triée
  }
  const handleFilterChange = (event) => {
    const searchString = removeAccents(event.target.value).toLowerCase();
    setFilteredCustomers(customers.filter(c => c.searchString.startsWith(searchString)));
  }
  const compareCustomers = (a, b) => {
    if (a.custName < b.custName) return -1;
    if (a.custName > b.custName) return 1;
    if (a.custFirstname < b.custFirstname) return -1;
    if (a.custFirstname > b.custFirstname) return 1;
    return 0;
  };
  return (
    <div id="AdminCustomersList">
      <div className="container">
        <div className='row'>
          <div className='col-6 '>
            <div className='form-group'>
              <div className='input-group'>
                <span className="input-group-text" id="basic-addon1"><FaSearch /></span>
                <input type="text" className="form-control" placeholder="Rechercher" onChange={handleFilterChange} />
              </div>
            </div>
          </div>
          <div className='col-6 text-end'>
            <button className='btn btn-md btn-outline-warning' onClick={e => create_customer()}>Ajouter un client</button>
          </div>
        </div>
        <table className='table table-sm table-striped fs-7'>
          <thead>
            <tr>
              <th>{t('Nom')}</th>
              <th>{t('Prénom')}</th>
              <th>{t('Téléphone')}</th>
              <th>{t('Email')}</th>
              <th className='text-center'>{t('Abonné')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredCustomers.map(customer => (
              <tr key={customer.custId}>
                <td>{customer.custName}</td>
                <td>{customer.custFirstname}</td>
                <td>{customer.user?.userPhone}</td>
                <td>{customer.user?.userEmail}</td>
                <td className='text-center'>{customer.custSubscription != 'none' && <FaCheck />}</td>
                <td className="text-end">
                  <span className='pointer' onClick={e => setEditedCustomer(customer)}>
                    <MdOpenInNew size={16} />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Modal */}
      {editedCustomer &&
        <Modal show={editedCustomer} onHide={e => setEditedCustomer(null)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{editedCustomer.custFirstname} {editedCustomer.custName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AdminCustomer customer={editedCustomer} onSaved={handleCustomerSaved} />
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>}
    </div>
  );
};
export default AdminCustomersList;
