// AdminOrder.js
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import API from './../../../api';
import FluxComponent from './FluxComponent';
import AdminOrderDetail from './AdminOrderDetails';
import { formatDate, debug, formatPrice, formatPhoneNumber } from './../../../utils';
import { MdOpenInNew, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdManageSearch } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import { GrCaretPrevious, GrCaretNext } from "react-icons/gr";
function AdminOrders() {
  const range_length = 50;
  const [pageIdx, setPageIdx] = useState(0);
  const [maxPageIdx, setMaxPageIdx] = useState(1000);
  const [orders, setOrders] = useState([]);
  const [filters, setFilters] = useState({ ordNumber: '', custName: '' });
  const [filteredOrders, setEditedOrders] = useState([]);
  const [editedOrder, setEditedOrder] = useState(null)
  const [fetching, setFetching] = useState(false)
  const { t } = useTranslation();
  useEffect(() => {
    const interval = window.setInterval(() => {
      syncOrder();
    }, 60000);
    //syncOrder();
    return () => window.clearInterval(interval);
  }, [orders]);
  useEffect(() => {
    if (fetching == true) {
      return;
    }
    setFetching(true);
    const fetchData = async () => {
      const qs = `?include=customers&limit=${pageIdx * range_length},${range_length}`;
      try {
        const response = await API.get('/admin/orders' + qs);
        const orders = response.data;
        orders.map(order => {
          const fullName = `${order.customer.custName} ${order.customer.custFirstname}`;
          order.custSearchString = fullName
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          return order;
        });
        setOrders(response.data);
        if (response.data?.length < range_length) {
          setMaxPageIdx(pageIdx)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      window.setTimeout(() => { setFetching(false); }, 500)
    };
    fetchData();
  }, [pageIdx]);
  useEffect(() => {
    const filterCustName = filters.custName
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
    const filteredOrders = orders.filter(order => {
      if (filters.ordNumber != '' && !order.ordNumber.includes(filters.ordNumber) && !order.ordUposNumber.toLowerCase().includes(filters.ordNumber.toLowerCase())) {
        return false;
      }
      if (filterCustName != '' && !order.custSearchString.includes(filterCustName)) {
        return false;
      }
      return true;
    });
    setEditedOrders(filteredOrders)
  }, [orders, filters]);
  const handleFiltersChange = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value })
  }
  const handleOrderUpdated = (order) => {
    const ordersCopy = [...orders];
    const idx = ordersCopy.findIndex(o => o.ordId == order.ordId);
    ordersCopy[idx] = { ...order }
    setOrders(ordersCopy);
  }
  const syncOrder = async () => {
    const ordersCopy = [...orders];
    console.log('Original orders:', orders);

    const qs = "query=" + JSON.stringify({ 'ordUpdatedAt:gte': moment().format('YYYY-MM-DD') });
    try {
      const response = await API.get('/admin/orders/synch?' + qs);
      if (!Array.isArray(response.data)) {
        console.error('Expected an array from API:', response.data);
        return;
      }

      response.data.forEach(order => {
        const index = ordersCopy.findIndex(o => o.ordId === order.ordId);
        if (index > -1) {

          ordersCopy[index] = { ...ordersCopy[index], ...order, customer: ordersCopy[index].customer };
          // ordersCopy[index].customer = ordersCopy[index].customer
          console.log(`Updated order at index ${index}:`, ordersCopy[index]);
        } else {
          ordersCopy.push(order);
          console.log('Added new order:', order);
        }
      });

      console.log('Updated orders:', ordersCopy);
      setOrders(ordersCopy);
    } catch (e) {
      console.error('Error during sync:', e);
    }
  }

  return (
    <div id="adminOrder">
      <div className='container'>
        <div className='row align-items-end mb-4'>
          <div className='col-6'>
            <h1 className='mb-0'>Commandes</h1>
          </div>
          <div className='col-6'>
            <div className='page-nav text-end pt-4'>
              <div className='btn-group'>
                <button
                  className='btn btn-sm btn-outline-warning'
                  onClick={e => setPageIdx(Math.max(0, pageIdx - 1))}
                  disabled={pageIdx == 0}>
                  <GrCaretPrevious size={20}
                  />
                </button>
                <button
                  className='btn btn-sm btn-outline-warning'
                  onClick={e => setPageIdx(Math.min(maxPageIdx, pageIdx + 1))}
                  disabled={pageIdx == maxPageIdx}>
                  <GrCaretNext size={20} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <table className='table table-sm table-bordered'>
          <thead>
            <tr>
              <th className='text-center' style={{ width: '120px' }}>Numéro</th>
              <th className='text-center' style={{ width: '90px' }}>Date</th>
              <th className='text-center'>Client</th>
              <th className='text-center' style={{ width: '90px' }}>A régler</th>
              <th className='text-center' style={{ width: '90px' }}>Réglé</th>
              <th className='text-center' style={{ width: '90px' }}>Solde</th>
              <th className='text-center' style={{ width: '120px' }}>Flux</th>
              <th style={{ width: '40px' }}></th>
              <th className='text-center' style={{ width: '7px' }}></th>
            </tr>
            <tr>
              <td>
                <div className='input-group'>
                  <input
                    type="text"
                    className='form-control form-control-sm py-0 h-auto fs-8 text-center'
                    name="ordNumber"
                    value={filters.ordNumber}
                    onChange={handleFiltersChange}
                  />
                  <span className='input-group-text px-1'><FaSearch size={10} /></span>
                </div>
              </td>
              <td></td>
              <td>
                <div className='input-group'>
                  <input
                    type="text"
                    className='form-control form-control-sm py-0 h-auto fs-8'
                    name="custName"
                    value={filters.custName}
                    onChange={handleFiltersChange}
                  />
                  <span className='input-group-text px-1'><FaSearch size={10} /></span>
                </div>
              </td>
              <td colSpan={7}></td>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order, idx) => (
              <tr key={order.ordId}>
                <td className='text-center'>{order.ordNumber}<br /><em>{order.ordUposNumber}</em></td>
                <td>{formatDate(order.ordCreatedAt)}</td>
                <td>{order.customer?.custName} {order.customer?.custFirstname}<br />
                  {order.customer?.user?.userEmail}<br />
                  {order.customer?.user?.userPhone}
                </td>
                <td className='text-center'>
                  {formatPrice(order.ordToBePaid)}
                </td>
                <td className='text-center'>
                  {formatPrice(order.ordPaid)}
                </td>
                <td className='text-center'>
                  {formatPrice(order.ordBalance)}
                </td>
                <td>
                  <FluxComponent ordFlows={order.ordFlows} />
                </td>
                <td className='text-center' style={{ width: '50px' }}>
                  <span className='pointer' onClick={e => setEditedOrder(order)} >
                    <MdOpenInNew size={16} />
                  </span>
                </td>
                <td style={{ background: order.ordPaymentStatus == '' ? `var(--unpaid` : `var(--${order.ordPaymentStatus})` }}></td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='page-nav text-end pt-4'>
          <div className='btn-group'>
            <button
              className='btn btn-sm btn-outline-warning'
              onClick={e => setPageIdx(Math.max(0, pageIdx - 1))}
              disabled={pageIdx == 0}>
              <GrCaretPrevious size={20}
              />
            </button>
            <button
              className='btn btn-sm btn-outline-warning'
              onClick={e => setPageIdx(Math.min(maxPageIdx, pageIdx + 1))}
              disabled={pageIdx == maxPageIdx}>
              <GrCaretNext size={20} />
            </button>
          </div>
        </div>
      </div>
      {editedOrder &&
        <Modal show={editedOrder} onHide={e => setEditedOrder(null)} size="xl">
          <Modal.Header closeButton>
            <strong>{editedOrder.ordNumber}</strong>
          </Modal.Header>
          <Modal.Body >
            <div style={{ paddingTop: '40px' }}>
              <AdminOrderDetail orderId={editedOrder.ordId} onUpdated={handleOrderUpdated} />
            </div>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>}
    </div >
  );
}
export default AdminOrders;
/*
          */