// https://fullcalendar.io/docs#toc
import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import API from '../../../api';
import AdminPickupOccurrence from './AdminPickupOccurrence'
import FullCalendar from '@fullcalendar/react'
import interactionPlugin from '@fullcalendar/interaction';
import { FaEdit, FaPaperclip } from 'react-icons/fa';
import moment from 'moment';
import { download } from '../../../utils';
import dayGridPlugin from '@fullcalendar/daygrid';
import Modal from 'react-bootstrap/Modal';
import allLocales from '@fullcalendar/core/locales-all'
import Switcher from '../../helpers/Switcher/Switcher';
import './../../../css/calendar.css';
import './admin-pickups-calendar.css';
function AdminPickupCalendar() {
  const weekendsVisible = false;
  const [paidOrderOnly, setPaidOrderOnly] = useState(false);
  const [occurrences, setOccurrences] = useState([]);
  const [events, setEvents] = useState([]);
  const [stats, setStats] = useState({});
  const [editedOccurrence, setEditedOccurrence] = useState(null);
  const today = moment().format('YYYY-MM-DD');
  useEffect(() => {
  }, []);
  useEffect(() => {
    const CustomComponent = () => {
      return (
        <div >
          {false && <div className=''>
            <label className='small mb-1'>Commandes réglées uniquement</label><br />
            <label className='small d-flex align-items-center justify-content-center'>
              Non &nbsp;
              <Switcher onClassName='switcher-on' onChange={e => { setPaidOrderOnly(!paidOrderOnly) }} value={paidOrderOnly} />
              &nbsp; Oui
            </label>
          </div>}
        </div>
      );
    };
    createRoot(document.querySelector('.fc-toolbar-chunk:nth-child(3)')).render(<CustomComponent />);
    calcStats();
  }, [paidOrderOnly]);
  const handleDatesSet = async (args) => {
    const query = { 'poDate:gte': args.startStr.substr(0, 10), 'poDate:lte': args.endStr.substr(0, 10) };
    const qs = '?include=pickups,order_items&query=' + JSON.stringify(query);
    const response = await API.get('/admin/occurrences' + qs);
    setOccurrences(response.data);
  }
  useEffect(() => {
    const eventsCopy = [];
    occurrences.forEach(po => {
      const event = {
        id: po.poId,
        title: po.pickup.picName,
        start: po.poDate,
        end: po.poDate,
        allDay: true,
      }
      eventsCopy.push(event);
    });
    calcStats();
    setEvents(eventsCopy);
  }, [occurrences]);
  const calcStats = () => {
    const statsCopy = {};
    occurrences.forEach(po => {
      statsCopy[po.poId] = {
        unpaid: po.order_items.filter(oi => oi.oiStatus === 'pending'),
        paid: po.order_items.filter(oi => oi.oiStatus !== 'pending'),
        collecte: po.order_items.filter(oi => oi.oiFlow === 'collecte' && (paidOrderOnly == false || oi.oiStatus !== 'pending')),
        restitutions: po.order_items.filter(oi => oi.oiFlow === 'restitution' && (paidOrderOnly == false || oi.oiStatus !== 'pending'))
      }
    });
    setStats(statsCopy);
  }
  const renderEventContent = (eventInfo) => {
    const po = occurrences.find(occ => occ.poId === eventInfo.event.id);
    console.log(po)
    return (
      (stats && po && po.poId && stats[po.poId]) && <div className='occurrence d-flex justify-content-between align-items-center py-1 ps-1'>
        <div className='left'>
          <b>{eventInfo.timeText.replace(' h', ':00')}</b>
          <span> {eventInfo.event.title}</span>
        </div>
        <div className='right'>
          {stats[po.poId]?.collecte?.length > 0 &&
            <span className='collect  ms-1' title="Collecte">{stats[po.poId]?.collecte?.length}</span>
          }
          {stats[po.poId]?.restitutions?.length > 0 &&
            <span className='restitution ms-1' title="Restitution">{stats[po.poId]?.restitutions?.length}</span>
          }

        </div>
      </div>
    );
  }
  const handleCalendarClick = (event) => {
  }
  const handleEventClick = async (clickInfo) => {
    console.log(clickInfo.jsEvent.ctrlKey)
    if (clickInfo.jsEvent.ctrlKey) {
      download('/admin/occurrence/' + clickInfo.event.id + '/pdf')
    } else {
      const occurrence = occurrences.find(occ => occ.poId === clickInfo.event.id);
      const qs = '?include=pickup,order_items,customers,cities,streets';
      const response = await API.get('/admin/occurrence/' + clickInfo.event.id + qs);
      setEditedOccurrence({ ...response.data });
    }
  }
  const handleDateSelect = (selectInfo) => {
  };
  const eventClassNames = (eventInfo) => {
  }
  const handleEvents = (events) => {
  }
  return (
    <>
      <div className='container'>
        <div className='calendar admin-pickups-calendar' onClick={handleCalendarClick}>
          <FullCalendar
            locales={allLocales} locale={'fr'}
            plugins={[interactionPlugin, dayGridPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: '',
            }}
            initialView="dayGridMonth"
            editable={false}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={false}
            dayMaxEventRows={true}
            initialDate={today}
            weekNumbers={true}
            aspectRatio={2}
            expandRows={true}
            weekends={weekendsVisible}
            events={events} // alternatively, use the `events` setting to fetch from a feed
            select={handleDateSelect}
            eventContent={renderEventContent} // custom render function
            datesSet={handleDatesSet}
            eventClassNames={eventClassNames}
            eventClick={handleEventClick}
            eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          />
        </div>
      </div>
      {editedOccurrence?.poId &&
        <Modal show={true} onHide={e => setEditedOccurrence(null)} className="admin-pickup-modal" size="lg" keyboard={true} >
          <Modal.Header closeButton>
            <Modal.Title>Tournée &laquo;{editedOccurrence.pickup.picName}&raquo; du {editedOccurrence.poDate}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AdminPickupOccurrence occurrence={editedOccurrence} paidOrderOnly={paidOrderOnly} />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      }
    </>);
}
export default AdminPickupCalendar;
