
// AdminPickupSelector.js
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import API from './../../../api';
import Modal from 'react-bootstrap/Modal';
import AddressSelector from './../../cities/AddressSelector'
import { useDispatch, useSelector } from 'react-redux';
import { debug, removeAccents, swalAlert, formatDate, ucFirst } from './../../../utils';
import { MdOpenInNew, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdManageSearch, MdAdd } from "react-icons/md";
import './admin-order-modal-edit-item.css';
function AdminOrderModalEditItem({ item, onChange, onHide }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [pickups, setPickups] = useState([]);
  const [restitutionMode, setRestitutionMode] = useState('');
  const [modalItem, setModalItem] = useState(null);
  useEffect(() => {
    setModalItem({ ...item })
    setRestitutionMode(item.oiPickupOccurrenceCityId !== 'poc0000000000000' ? 'delivery' : 'pickup'); // pickup = retrait à la repasserie
  }, [item]);
  const get_pickups = async (areaId = null) => {

    try {
      const response = await API.get(`/admin/city/${areaId ? areaId : item.oiAreaId}/pickups_occurrences`);
      setPickups(response.data);
    } catch (e) {

    }
  }
  const hanldeAddressChange = () => {


  }
  const hanldeAddressSelected = (address) => {
    if (address) {
      setModalItem({
        ...modalItem,
        oiAreaId: address.adrAreaId,
        oiAdrId: address.adrId,
        oiCityId: address.adrCityId,
        address: address
      });
      get_pickups(address.adrAreaId);
    } else {
      setPickups([]);
    }
  }
  useEffect(() => {

    switch (restitutionMode) {
      case 'pickup':
        setModalItem({
          ...modalItem,
          oiPickupOccurrenceCityId: 'poc0000000000000',
          oiCityId: '',
          oiAreaId: '',
          oiAdrId: '',
          oiPickupId: '',
          oiPickupOccurrenceId: '',
          oiPickupOccurrenceCityId: '',
          oiAdrId: '',
        })
        setPickups([]);
        break;
      case 'delivery':
        if (item.oiPickupOccurrenceCityId != 'poc0000000000000') {
          /*
          setModalItem({
            ...modalItem,
            oiAreaId: '',
            oiAdrId:'',            
            oiCityId: item.oiCityId,
            oiPickupId: item.oiPickupId,
            oiPickupOccurrenceId: item.oiPickupOccurrenceId,
            oiPickupOccurrenceCityId: item.oiPickupOccurrenceCityId,
            oiAdrId: item.oiAdrId,
          });
          */
        }

        get_pickups();
        break;
    }
  }, [restitutionMode]);

  const itemIsValid = () => {
    return (
      item.oiPickupOccurrenceCityId === 'poc0000000000000'
      || (
        item.oiAreaId != '' && item.oiPickupOccurrenceCityId != ''
      )
    )
  }




  return (
    <Modal show={true} onHide={onHide} size='md' keyboard={false} backdrop="static" id="AdminOrderModalEditItem">
      <Modal.Header closeButton>
        <div className='row flex-grow-1' >
          <div className='col-sm-4'>
            <Modal.Title>
              {ucFirst(item.oiFlow)}
            </Modal.Title>
          </div>
          <div className='col-sm-8 text-end pe-4'>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group  mb-4">
          <div className="border rounded bg-white">
            <p className="bg-light  mb-0 fw-semibold fs-7 text-center py-1 ">Adresse de {item.oiFlow} : </p>
            {item.oiFlow == 'restitution' &&
              <div className=" pt-2 px-3 ">
                <div className="form-group d-flex justify-content-center mb-1">
                  <div className=" me-5 border px-2 py-0 rounded flex-grow-1">
                    <label className="radio-label" >
                      <input
                        type="radio"
                        name="restitutionMode"
                        value='delivery'
                        checked={restitutionMode == 'delivery'}
                        onChange={event => setRestitutionMode(event.target.value)}
                      />Livraison
                    </label>
                  </div>
                  <div className=" me-5 border  px-2 py-0  rounded  flex-grow-1">
                    <label className="radio-label">
                      <input
                        type="radio"
                        name="restitutionMode"
                        value='pickup'
                        checked={restitutionMode == 'pickup'}
                        onChange={event => setRestitutionMode(event.target.value)}
                      />Retrait à la repasserie
                    </label>
                  </div>
                </div>
              </div>
            }
            {(item.oiFlow == 'collecte' || restitutionMode === 'delivery') &&
              <div className=" pt-2 px-3 ">
                <AddressSelector
                  onChange={hanldeAddressChange}
                  selectedCityId={item.oiCityId}
                  selectedAddressId={item.oiAdrId}
                  addressSelected={item.oiAdrId}
                  onAddressSelected={hanldeAddressSelected}
                  inputClassName="form-control-sm" />
              </div>
            }
          </div>
        </div>
        <div style={{ minHeight: '50px' }}>

          {(item.oiFlow == 'collecte' || restitutionMode === 'delivery') &&
            <div className='form-goup mb-3'>
              <div className="border rounded bg-white">
                <p className="bg-light mb-0 text-center fw-semibold fs-7 py-1">Date de {item.oiFlow} : </p>

                <ul className="pickups-list  py-1 px-3 ">
                  {
                    pickups.map((pickup, index) => (
                      <li className={`my-2`} key={index}>
                        <div
                          className={`border  rounded text-center py-2 ${pickup.pocId == modalItem?.oiPickupOccurrenceCityId ? 'border-warning bg-light' : ''} pointer`}
                          onClick={e => setModalItem({
                            ...modalItem,
                            oiPickupOccurrenceId: pickup.pocOccurrenceId,
                            oiPickupOccurrenceCityId: pickup.pocId,
                            occurrence_city: pickup
                          })}
                        >
                          {ucFirst(formatDate(pickup.pocDate, 'textfull'))}  - de {pickup.pocSlotStart} à {pickup.pocSlotEnd} &nbsp; [{pickup.orders_count}/{pickup.max_orders_count}]
                        </div>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
          }
        </div>
        <div className='form-goup text-center'>
          <button
            type="button"
            disabled={!itemIsValid}
            className="btn btn-outline-warning"
            onClick={e => onChange(modalItem)}
          >Valider</button>
        </div>
        {debug(modalItem, 1)}

      </Modal.Body>
      <Modal.Footer>
        <div className='text-left flex-grow-1'>
        </div>
      </Modal.Footer>
    </Modal>

  );
}
export default AdminOrderModalEditItem;
