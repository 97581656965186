import React, { useEffect, useState, useRef } from 'react';
import API from '../../../api.js';
import { debug, formatPrice, swalAlert, isDev } from '../../../utils.js';
import AdminCategoryItem from './AdminCategoryItem.js'
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { BsSearch, BsFillPencilFill } from "react-icons/bs";
import { BiCaretDown, BiCaretUp } from "react-icons/bi";
import { MdDragHandle, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdManageSearch } from "react-icons/md";
import { FaPlus, FaTimes, FaRegTrashAlt } from 'react-icons/fa';
import cloneDeep from 'lodash/cloneDeep';
import Switcher from '../../helpers/Switcher/Switcher.js';
import Modal from 'react-bootstrap/Modal';
import Uploader from '../../helpers/uploader/Uploader.js';
import Picture from '../../helpers/Picture/Picture.js';
import AdminCatalogModalCategory from './AdminCatalogModalCategory.js';
import AdminCatalogModalProduct from './AdminCatalogModalProduct.js';
import AdminCatalogModalOption from './AdminCatalogModalOption.js';
import './admin-catalog.css';
import './../../../css/forms.css';
const AdminCatalog = () => {
  const [catalog, setCatalog] = useState({ categories: [], products: [], options: [] });
  const [editedCategory, setEditedCategory] = useState(null);
  const [editedProduct, setEditedProduct] = useState(null);
  const [editedOption, setEditedOption] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const [selectedCatId, setSelectedCatId] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [accessoires, setAccessoires] = useState([]);
  useEffect(() => {
    get_catalog();
  }, []);
  useEffect(() => {
    console.log('accessoires', accessoires)
  }, [accessoires]);





  const get_catalog = async () => {
    try {
      const response = await API.get('/admin/catalog');
      setCatalog(response.data)
      setFilteredProducts(response.data.products)
      const catAccessoires = response.data.categories.find(c => c.catSlug === 'Accessoires')
      console.log('catAccessoires', catAccessoires);
      console.log('Anna Gas')
      const accessoires = response.data.products.filter(p => p.prodCatId === catAccessoires.catId);
      console.log('accessoiresaccessoires', accessoires)
      setAccessoires(accessoires);
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (selectedCatId == '') {
      setFilteredProducts(cloneDeep(catalog.products));
    } else {
      const cateroriesArray = [selectedCatId];
      catalog.categories.forEach(c => {
        if (c.catParentId === selectedCatId) {
          cateroriesArray.push(c.catId);
        }
      })
      setFilteredProducts(catalog.products.filter(p => cateroriesArray.includes(p.prodCatId) && p.prodStatus !== 'archived'));
    }
  }, [catalog.products, selectedCatId]);
  const handleCategoryChange = (catId, payload) => {
    const categoriesCopy = catalog.categories.map(c => c.catId == catId ? { ...c, ...payload } : c);
    setCatalog({ ...catalog, categories: categoriesCopy });
  }
  const handleAddSubcategory = (catId) => {
    setEditedCategory({
      catParentId: catId,
      catName: '',
      catMemo: '',
      catCover: '',
      carOrder: catalog.categories.length,
      catStatus: 'active'
    })
  }
  const handleSaveEditedCategory = async (category) => {
    if (category !== null) {
      try {
        if (!category.catId) {
          const response = await API.post('/admin/catalog/category', category);
          setCatalog({ ...catalog, categories: [...catalog.categories, response.data] });
        } else {
          const response = await API.put('/admin/catalog/category/' + category.catId, category);
          const categoriesCopy = catalog.categories.map(c => c.catId == category.catId ? { ...response.data } : c);
          setCatalog({ ...catalog, categories: categoriesCopy.filter(c => c.catStatus != 'archived') });
        }
      } catch (e) {
      }
    }
    setEditedCategory(null);
  }
  const handleSaveEditedProduct = async (product) => {
    if (product !== null) {
      try {
        if (!product.prodId) {
          if (product.prodStatus != 'archived') {
            const response = await API.post('/admin/catalog/product', product);
            setCatalog({ ...catalog, products: [...catalog.products, response.data] });
          }
        } else {
          const response = await API.put('/admin/catalog/product/' + product.prodId, product);
          const productsCopy = catalog.products.map(p => p.prodId == product.prodId ? { ...response.data } : p);
          setCatalog({ ...catalog, products: productsCopy.filter(p => p.prodStatus != 'archived') });
        }
      } catch (e) {
      }
    }
    setEditedProduct(null);
  }
  const handleSaveEditedOption = async (option) => {
    if (option !== null) {
      try {
        if (!option.optId) {
          if (option.optStatus != 'archived') {
            const response = await API.post('/admin/catalog/option', option);
            setCatalog({ ...catalog, options: [...catalog.options, response.data] });
          }
        } else {
          const response = await API.put('/admin/catalog/option/' + option.optId, option);
          const optionsCopy = catalog.options.map(p => p.optId == option.optId ? { ...response.data } : p);
          setCatalog({ ...catalog, options: optionsCopy.filter(o => o.optStatus != 'archived') });
        }
      } catch (e) {
      }
    }
    setEditedOption(null);
  }
  const handleCategoryDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const parentId = catalog.categories.find(category => category.catId === draggableId).catParentId;
    const subcategories = catalog.categories.filter(category => category.catParentId === parentId);
    const reorderedSubcategories = Array.from(subcategories);
    const [removed] = reorderedSubcategories.splice(source.index, 1);
    reorderedSubcategories.splice(destination.index, 0, removed);
    const unUupdatedCategories = catalog.categories.filter(category => category.catParentId !== parentId);
    const updatedCategories = [...unUupdatedCategories, ...reorderedSubcategories];
    setCatalog({
      ...catalog,
      categories: updatedCategories,
    });
    setIsDirty(true);
  };
  const handleProductDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const product = catalog.products.find(p => p.prodId === draggableId);
    const parentId = product.prodCatId; // catalog.categories.find(c => c.catId === product.prodCatId).catId;
    const products = catalog.products.filter(p => p.prodCatId === parentId);
    const reorderedProducts = Array.from(products);
    const [removed] = reorderedProducts.splice(source.index, 1);
    reorderedProducts.splice(destination.index, 0, removed);
    const unUupdatedProducts = products.filter(p => p.prodCatId != parentId);
    const updatedProducts = [...unUupdatedProducts, ...reorderedProducts];
    setCatalog({
      ...catalog,
      products: updatedProducts,
    });
    setIsDirty(true);
  };
  const handleSaveCatalog = async () => {
    const response = await API.put('/admin/catalog', catalog);
    setCatalog(response.data);
    setIsDirty(false);
  }
  const breadcrumbs = (catId) => {
    let str = '';
    if (catalog && catalog.categories) {
      const categorie = catalog.categories.find(c => c.catId === catId)
      str = categorie?.catName;
      if (categorie && categorie?.catParentId !== '') {
        const parent = catalog.categories.find(c => c.catId === categorie.catParentId)
        str = parent?.catName + ' / ' + str;
      }
    }
    return str;
  }
  const handleProductChange = (productId, event) => {
    const { name, value } = event.target;
    const productsCopy = catalog.products.map(p => p.prodId === productId ? { ...p, [name]: value } : p)
    setCatalog({ ...catalog, products: productsCopy });
    setIsDirty(true);
  }
  return (
    <div id='AdminCatalog'>
      <div className="container">
        <div className='content-title'>
          <h1 onClick={get_catalog}>Tarifs</h1>
          <div className='buttons-group'>
            <button className={`btn ${isDirty ? 'btn-danger' : 'btn-outline-warning'}`} onClick={handleSaveCatalog}>Sauvegarder</button>
          </div>
        </div>
        <div className='row'>
          {/*    -------------------------------  CATEGORIES  ------------------------------------    */}
          <div className='col-4 categories-tree '>
            {catalog.categories &&
              <ul>
                {catalog.categories.filter(c => c.catParentId == '').map(category => (
                  <li className='' key={category.catId} >
                    <div className={`d-flex flex-wrap justify-content-between py-2 ${selectedCatId === category.catId ? 'fw-bold' : ''}`}>
                      <a onClick={e => setSelectedCatId(category.catId)}>{category.catName}</a>
                      <div>
                        <span className='d-inline-block pointer ps-2' onClick={e => setEditedCategory(category)}>
                          <BsFillPencilFill size={12} title="Modifier cette catégorie" />
                        </span>
                        <span className='d-inline-block pointer ps-2' onClick={e => handleAddSubcategory(category.catId)}>
                          <FaPlus size={12} title="Ajouter une sous-catégorie" />
                        </span>
                        <span className='d-inline-block pointer ps-2' onClick={e => handleCategoryChange(category.catId, { hideChildren: !category.hideChildren })}>
                          {category.hideChildren ? <BiCaretDown size={22} /> : <BiCaretUp size={22} />}
                        </span>
                      </div>
                    </div>
                    {!category.hideChildren === true && (
                      <DragDropContext onDragEnd={handleCategoryDragEnd}>
                        <Droppable droppableId={category.catId}>
                          {(provided) => (
                            <ul {...provided.droppableProps} ref={provided.innerRef}>
                              {catalog.categories.filter(c => c.catParentId === category.catId).map((subcategory, index) => (
                                <Draggable key={subcategory.catId} draggableId={subcategory.catId} index={index}>
                                  {(provided) => (
                                    <li className={`d-flex flex-wrap ${selectedCatId === subcategory.catId ? 'fw-bold' : ''}`}
                                      {...provided.draggableProps}
                                      ref={provided.innerRef}
                                    >
                                      <div className='py-1 flex-grow-1' {...provided.dragHandleProps} >
                                        <span className='drag-handle text-center' >
                                          <MdDragHandle />
                                        </span>
                                        <a onClick={e => setSelectedCatId(subcategory.catId)}>{subcategory.catName}</a>
                                      </div>
                                      <div className='pe-4'>
                                        <span className='d-inline-block pointer ps-2' onClick={e => setEditedCategory(subcategory)}>
                                          <BsFillPencilFill size={12} title="Modifier une sous-catégorie" />
                                        </span>
                                        {subcategory.catId && (
                                          <span className='d-inline-block pointer ps-2'
                                            onClick={e => setEditedProduct(
                                              {
                                                prodCatId: subcategory.catId,
                                                prodStatus: 'active',
                                                prodOptions: [],
                                                prodPricesList: [],
                                                prodAccessoires: [],
                                                prodShowPrice: true,
                                                prodMemo: ''
                                              })
                                            }>
                                            <FaPlus size={12} title="Ajouter un produit" />
                                          </span>)
                                        }
                                      </div>
                                    </li>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </ul>
                          )}
                        </Droppable>
                      </DragDropContext>
                    )}
                  </li>
                ))}
                {/*    -------------------------------  OPTIONS  ------------------------------------    */}
                <li className=''  >
                  <div className='d-flex flex-wrap justify-content-between py-2'>
                    Options
                    <div className='me-4'>
                      <span className='d-inline-block pointer ps-2 pe-2' onClick={e => setEditedOption({ optPrice: 0 })}>
                        <FaPlus size={12} title="Ajouter une options" />
                      </span>
                    </div>
                  </div>
                  <ul className='ps-4'>
                    {catalog.options.map((option, index) => (
                      <li className='ps-1' key={index}>
                        <div className='py-1'>
                          <div className='d-flex flex-wrap justify-content-between pe-2'>
                            <div>
                              {option.optName} &nbsp; ({formatPrice(option.optPrice)}€)
                            </div>
                            <div className='pe-4'>
                              <span className='d-inline-block pointer ps-2' onClick={e => setEditedOption(option)}>
                                <BsFillPencilFill size={12} title="Modifier cette option" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            }
          </div>
          <div className='col ps-5'>
            {/*    -------------------------------  PRODUITS  ------------------------------------    */}
            <div className='pb-2'>
              <h5 >
                {filteredProducts && (<>{selectedCatId !== '' ? breadcrumbs(selectedCatId) : 'Toutes les catégories'} - {filteredProducts.length} produit{filteredProducts.length > 0 ? 's' : ''} </>)}
              </h5>
            </div>
            <DragDropContext onDragEnd={handleProductDragEnd}>
              <Droppable droppableId="products">
                {(provided) => (
                  <table className='table table-striped table-sm table-bordered'>
                    <thead className='spacer'>
                      <tr >
                        <td style={{ width: '40px' }}></td>
                        <td style={{ width: '100px' }}></td>
                        <td></td>
                        <td style={{ width: '180px' }}></td>
                        <td style={{ width: '80px' }}></td>
                        <td style={{ width: '100px' }}></td>
                        <td style={{ width: '80px' }}></td>
                      </tr>
                    </thead>
                    <tbody ref={provided.innerRef} {...provided.droppableProps}  {...provided.dragHandleProps} >
                      {filteredProducts && filteredProducts.map((product, index) => (
                        <Draggable key={product.prodId} draggableId={product.prodId} index={index}>
                          {(provided) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <td >
                                <span className='drag-handle text-center'  {...provided.dragHandleProps}>
                                  <MdDragHandle />
                                </span>
                              </td>
                              <td className='text-center'>
                                {product.prodCover != '' && <Picture src={product.prodCover} size='maxw=80,maxh=80' />}
                              </td>
                              <td className='text-left'>
                                {product.prodDesignation}
                                {product.prodRef != '' && <em className='small'><br />{product.prodRef}</em>}
                              </td>
                              <td className='text-center'>
                                {catalog.categories.find(c => c.catId === product.prodCatId)?.catName}
                              </td>
                              <td className='text-center'>
                                {formatPrice(product.prodPricesList[0]?.p)}
                              </td>
                              <td className='text-center'>
                                {product.prodUnity}
                              </td>
                              <td className='text-center'>
                                <Switcher onValue="active" offValue="inactive" name='prodStatus' value={product.prodStatus} className='' onClassName='' offClassName=''
                                  onChange={event => handleProductChange(product.prodId, event)} />
                              </td>
                              <td>
                                {product.prodId && (
                                  <span className='d-inline-block pointer ps-2' onClick={e => setEditedProduct({ ...product })}>
                                    <BsFillPencilFill size={12} title="Modifier ce produit" />
                                  </span>)
                                }
                              </td>
                            </tr>)}
                        </Draggable>
                      ))}
                    </tbody>
                  </table>)}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>
      {editedCategory &&
        <AdminCatalogModalCategory
          category={{ ...editedCategory }}
          categories={[...catalog.categories]}
          onSave={category => handleSaveEditedCategory(category)}
        />}
      {editedProduct &&
        <AdminCatalogModalProduct
          product={{ ...editedProduct }}
          categories={[...catalog.categories]}
          accessoires={accessoires}
          options={[...catalog.options]}
          onSave={product => handleSaveEditedProduct(product)}
        />}
      {editedOption &&
        <AdminCatalogModalOption
          option={{ ...editedOption }}
          onSave={option => handleSaveEditedOption(option)}
        />}

    </div>
  );
}
export default AdminCatalog
