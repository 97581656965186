import React, { useEffect, useState, useRef } from 'react';
import API from '../../../api.js';
import { debug, formatPrice, swalAlert, isDev } from '../../../utils.js';
import AdminCategoryItem from './AdminCategoryItem.js'
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { BsSearch, BsFillPencilFill } from "react-icons/bs";
import { BiCaretDown, BiCaretUp } from "react-icons/bi";
import { MdDragHandle, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdManageSearch } from "react-icons/md";
import { FaPlus, FaTimes, FaRegTrashAlt } from 'react-icons/fa';
import cloneDeep from 'lodash/cloneDeep';
import Switcher from '../../helpers/Switcher/Switcher.js';
import Modal from 'react-bootstrap/Modal';
import Uploader from '../../helpers/uploader/Uploader.js';
import Picture from '../../helpers/Picture/Picture.js';

import './admin-catalog.css';
import './../../../css/forms.css';
const AdminCatalogModalCategory = ({ category, categories, onSave }) => {

  const [modalCategory, setModalCategory] = useState(null);
  useEffect(() => {
    setModalCategory(category);
  }, [category])

  return (
    <Modal show={true} onHide={e => { onSave(null) }}

      size='lg'
      keyboard={false}
      backdrop='static' >
      <Modal.Header closeButton>
        <div className='row flex-grow-1' >
          <div className='col-sm-12'>
            <Modal.Title>
              {modalCategory?.catId ? 'Modifier ' : ' Ajouter '} une catégorie
            </Modal.Title>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>

        {modalCategory && <form>
          <div className='form-group'>
            <label className='form-group-label' required>Nom : </label>
            <input className='form-control form-control-sm' name='catName' type='text' value={modalCategory.catName} onChange={e => setModalCategory({ ...modalCategory, catName: e.target.value })} />
          </div>
          <div className='form-group'>
            <label className='form-group-label' >Note : </label>
            <textarea className='form-control form-control-sm' name='catMemo' type='text' value={modalCategory.catMemo} onChange={e => setModalCategory({ ...modalCategory, catMemo: e.target.value })} />
          </div>
          <div className='row'>
            <div className='col'>
              <div className='form-group'>
                <label className='form-group-label' required>Photo : </label>
                {modalCategory.catCover && (
                  <div>
                    <Picture src={modalCategory.catCover} height={200} />
                    <div className='py-0'>
                      <span className='d-inline-block pe-2 pointer' onClick={e => setModalCategory({ ...modalCategory, catCover: '' })}>
                        <FaTimes />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-sm-3'>
              <Uploader inputProps={{ accept: '.jpg,.webp' }} onUploadSuccess={upload => setModalCategory({ ...modalCategory, catCover: upload.path })} />
            </div>
          </div>
          <div className='d-flex justify-content-between w-50'>
            <div className='col text-center d-flex align-items-center justify-content-start'>
              <label className='form-group-label me-3 pt-1' required>Affiché : </label>
              <Switcher onValue="active" offValue="inactive" name='catStatus' value={modalCategory.catStatus} className='' onClassName='' offClassName=''
                onChange={e => setModalCategory({ ...modalCategory, catStatus: e.target.value })} />
            </div>
            <div className='col-3 text-center'>
              {modalCategory.catId && <button type="button" className='btn btn-sm btn-outline-secondary'
                onClick={e => { setModalCategory({ ...modalCategory, catStatus: 'archived' }); }}>
                <FaRegTrashAlt />
              </button>
              }
            </div>
          </div>
          <div className='row pt-5'>
            <div className='col-sm-4'>
              <button type="button" className='btn btn-outline-warning' onClick={e => onSave(modalCategory)}
              >Sauvegarder</button>
            </div>
          </div>
          {modalCategory.catStatus == 'archived' && <p className='small text-red pt-2'>Cete catégorie sera supprimée.</p>}
        </form>}



        {debug(modalCategory)}

      </Modal.Body>
      <Modal.Footer>
        <div className='text-left flex-grow-1'>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
export default AdminCatalogModalCategory