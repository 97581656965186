// AdminCities.js
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import API from './../../api';
import InputAutocomplete from '../helpers/InputAutocomplete/InputAutocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { debug, removeAccents, swalAlert } from './../../utils';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdManageSearch } from "react-icons/md";
import './address-selector.css';
import './../../css/forms.css';
function AddressSelector({
  selectedCityId = null,
  selectedAddressId = null,
  onAddressSelected,
  disabled = false,
  readonly = false,
  inputClassName = '',
  onChange = null
}) {
  const suggestCities = useRef(null);
  const suggestAddresses = useRef(null);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState({ citName: '' });
  const [addresses, setAddresses] = useState([]);
  const [filteredAdress, setFilteredAddesses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState({ adrText: '' });
  useEffect(() => { }, []);
  useEffect(() => {
    get_cities();
  }, []);
  useEffect(() => {
    if (cities.length > 0) {
      if (selectedCityId) {
        const city = cities.find(c => c.citId === selectedCityId);
        if (city) {
          setSelectedCity(city);
          get_addresses(selectedCityId);
        }
      } else {
        suggestCities.current.focus();
      }
    }
  }, [cities]);
  useEffect(() => {
    if (selectedAddressId) {
      const address = addresses.find(a => a.adrId === selectedAddressId);
      if (address) {
        setSelectedAddress(address);
      }
    }
  }, [addresses]);
  const get_cities = async () => {
    const response = await API.get('/cities');
    const uniqParentId = {};
    response.data.forEach(area => {
      if (!uniqParentId[area.citParentId]) {
        uniqParentId[area.citParentId] = area;
      }
    });
    setCities(Object.values(uniqParentId));
  }
  const get_addresses = async (citParentId) => {
    const response = await API.get('/city/' + citParentId + '/addresses');
    const addresses = response.data;
    addresses.sort((a, b) => {
      if (a.adrId < b.adrId) {
        return -1;
      }
      if (a.adrId > b.adrId) {
        return 1;
      }
      return 0;
    });
    setFilteredAddesses(addresses)
    setAddresses(addresses.map(a => ({
      ...a,
      searchStr: removeAccents(a.adrText.toLowerCase().replace(/\s+/g, '')) // voir pour 
    })));
  }
  const handleSuggestionSelect = (name, value) => {
    switch (name) {
      case 'city':
        setSelectedCity(value);
        get_addresses(value.citParentId);
        setSelectedAddress({ adrText: '' });
        suggestAddresses.current.focus();
        onAddressSelected(null);
        break;
      case 'address':
        value.citName = cities.find(c => c.citParentId == value.adrCityId).citPublicName;

        setSelectedAddress(value);
        onAddressSelected(value);
        break;
    }
  }
  const handleSuggestChange = (event) => {
    onAddressSelected(null);
    if (event.target.name == 'city') {
      setSelectedCity({ ...selectedCity, citPublicName: event.target.value })
    }
    if (event.target.name == 'address') {
      setSelectedAddress({
        ...selectedAddress, adrText: event.target.value
      });
    }
    if (onChange) {
      onChange();
    }
  }
  return (
    <div id="AddressSelector">
      <div className="form-group mb-2" >
        <label className="form-group-label">Commune :</label>
        <InputAutocomplete
          inputClassName={inputClassName}
          inputRef={suggestCities}
          disabled={disabled}
          readonly={readonly}
          suggestionsSrc={cities}
          name='city'
          placeholder=''
          value={selectedCity.citPublicName}
          displayKey="citPublicName"
          onChange={handleSuggestChange}
          formatSuggestion={(key, city) => city.citPublicName}
          onSuggestionSelect={handleSuggestionSelect}
          debug={true} />
      </div>
      <div className="form-group">
        <label className="form-group-label required">Adresse : </label>
        <InputAutocomplete
          inputClassName={inputClassName}
          inputRef={suggestAddresses}
          suggestionsOnFocus={false}
          disabled={disabled}
          readonly={readonly}
          suggestionsSrc={filteredAdress}
          filterMode='includes'
          name='address'
          autoComplete="off"
          placeholder='Numéro + nom de la rue'
          value={selectedAddress.adrText + (selectedAddress.adrSupText ? ', ' + selectedAddress.adrSupText : '')}
          displayKey="adrText"
          onChange={handleSuggestChange}
          formatSuggestion={(key, addr) => addr.adrText + (addr.adrSupText ? ', ' + addr.adrSupText : '')}
          onSuggestionSelect={handleSuggestionSelect}
          debug_on={true} />
        {debug(cities, 0)}
      </div>
    </div >
  );
}
export default AddressSelector;
