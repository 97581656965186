import React, { useEffect, useState, useRef } from 'react';
import './switcher.css';
const Switcher = ({ onValue = true, offValue = false, name, value = false, className = '', onClassName = '', offClassName = '', onChange = null, disabled = false }) => {
  const [switcherValue, setSwitcherValue] = useState(value)
  const handleChange = (event) => {
    if (disabled == false) {
      let newValue = '';
      if (event.target.tagName == 'LABEL' && (switcherValue !== onValue && switcherValue !== offValue)) {
        const rect = event.currentTarget.getBoundingClientRect();
        const middleX = rect.left + rect.width / 2;
        if (event.clientX > middleX) {
          newValue = onValue;
        } else {
          newValue = offValue;
        }
      } else {

        if (value == onValue) {
          newValue = offValue;
        } else {
          newValue = onValue;
        }
      }


      onChange({
        target: {
          name: name,
          value: newValue,
        }
      });
    }
  }
  useEffect(() => {
    // setSwitcherValue(alue)
  }, [value])

  return (
    <>
      <label
        className={`switcher ${className} ${value == onValue ? 'on ' + onClassName : value == offValue ? 'off ' + offClassName : ''} ${disabled ? 'disabled' : ''}`}
        onClick={handleChange} >
        <span className='handler' onClick={handleChange} data-value={value}></span>
      </label>
    </>
  )

}


export default Switcher;