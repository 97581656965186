import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation, Link, useNavigate, Navigate } from 'react-router-dom';
// import { clearUser, fetchUser } from './../../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineArrowLeft } from 'react-icons/ai'; // Importe l'icône souhaitée depuis React Icons
import { debug, formatDate } from './../../../utils';
import API from './../../../api';

const AdminWorkshopConnect = ({ }) => {
  const [devices, setDevices] = useState([]);
  useEffect(() => {
    getDevices();
  }, []);
  const getDevices = async () => {
    const response = await API.get('/admin/workshop/devices');
    setDevices(response.data);

  }
  return (
    <div>
      <div className='container'>
        <div className='content-title'>
          <h1>Connexion des tablettes</h1>

        </div>
        <div className='row'>

          <div className='col-md-3 text-center border rounded p-3'>

            <h2>TABLETTE 1</h2>
            <img src="https://app.laffairearepasser.fr/msmedias/workshop/qrc-tablette1.png?v=2" />


          </div>
          <div className='col-md-1 '></div>
          <div className='col-md-3 text-center border rounded p-3'>

            <h2>TABLETTE 2</h2>
            <img src="https://app.laffairearepasser.fr/msmedias/workshop/qrc-tablette2.png?v=2" />


          </div>
          <div className='col-md-1 '></div>
          <div className='col-md-3 text-center border rounded p-3'>

            <h2>TABLETTE 3</h2>
            <img src="https://app.laffairearepasser.fr/msmedias/workshop/qrc-tablette3.png?v=2" />


          </div>

        </div>
      </div>
    </div>
  )
}
export default AdminWorkshopConnect;


/*
https://app.laffairearepasser.fr/workshop/workshop.php?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiI2NjQzNzVkNzM2N2M2Iiwic2NvcGUiOiJhZG1pbiIsImlhdCI6MTcxNTY5NzI2OSwiZXhwIjoxNzQ3MjMzMjY5fQ.QjluMeHwrSliwAVG8fIVJxe9rja1WG7yxtiJ3X4Uzak
*/