// Customer.js
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import API from '../../api';
import CustomAlert from '../helpers/CustomAlert/CustomAlert';
import Picture from '../helpers/Picture/Picture';
import { debug, download } from '../../utils'
const CustomerAccount = ({ customer, onUpdated = null }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const [customerCopy, setCustomerCopy] = useState({});
  const [submission, setSubmission] = useState(null);
  const [displayLoyaltyQrc, setDisplayLoyaltyQrc] = useState(false);
  useEffect(() => {
    setCustomerCopy(customer)
    setDisplayLoyaltyQrc(customer.custLoyaltyNumber !== '')
  }, [customer]);
  const handleChange = (e) => {


    const { name, value } = e.target;
    if (name == 'custLoyaltyNumber') {
      //  setDisplayLoyaltyQrc(false);
    }
    setCustomerCopy(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = async () => {
    setSubmission(null);
    try {
      if (!customerCopy.custName.trim() || !customerCopy.custFirstname.trim()) {
        throw new Error("Veuillez renseigner votre nom et votre prénom");
      }
      if (customerCopy.custLoyaltyNumber.trim() != '' && customerCopy.custLoyaltyNumber.length != 6) {
        throw new Error("Le code fidélité est composé de 6 chiffres.");
      }
      const response = await API.put(`/customer/${customer.custId}`, customerCopy);
      setSubmission({ status: response.status, message: 'Votre compte a été modifié.' });
      onUpdated(response.data)
    } catch (error) {
      console.log(error)
      setSubmission(error);
    }
  }
  return (
    <div id="CustomerAccount">
      <form>
        <div className='row'>
          <div className='col-md-8 col-lg-6'>
            <div className="form-group">
              <label className="form-group-label" required>Nom :</label>
              <input
                type="text"
                name="custName"
                value={customerCopy.custName}
                onChange={handleChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="form-group">
              <label className="form-group-label" required>Prénom :</label>
              <input
                type="text"
                name="custFirstname"
                value={customerCopy.custFirstname}
                onChange={handleChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className="form-group">
              <label className="form-group-label">Adresse de facturation : </label>
              <textarea
                type="text"
                name="custAddress"
                value={customerCopy.custAddress}
                onChange={handleChange}
                className="form-control form-control-sm"
              />
            </div>
            <div className='row'>
              <div className='col-4 col-sm-4'>
                <div className="form-group">
                  <label className="form-group-label">Code Postal :</label>
                  <input
                    type="text"
                    name="custPC"
                    value={customerCopy.custPC}
                    onChange={handleChange}
                    className="form-control form-control-sm"
                  />
                </div>
              </div>
              <div className='col-8 col-sm-8'>
                <div className="form-group">
                  <label className="form-group-label">Ville :</label>
                  <input
                    type="text"
                    name="custCity"
                    value={customerCopy.custCity}
                    onChange={handleChange}
                    className="form-control form-control-sm"
                  />
                </div></div></div>
            <div className='row mb-5' >
              <div className='col-4 col-sm-4'>
                <div className="form-group">
                  <label className="form-group-label">Pays</label>
                  <input
                    type="text"
                    name="custCountry"
                    value={customerCopy.custCountry}
                    onChange={handleChange}
                    className="form-control form-control-sm"
                  />
                </div>
              </div>
              <div className='col-1 col-sm-1 '></div>
              <div className='col-6 col-sm-6 '>

                {displayLoyaltyQrc && <div className=' text-center '>
                  {/* 
                    <p className='small mb-0 pb-0 '>Mon QR-Code Fidélité</p>
                    <Picture src={`/customer/loyaltyqrc`} width={120} />
                  */}
                  <div className="form-group">
                    <label className="form-group-label">Numéro de la carte de fidélité :</label>
                    <input
                      type="text"
                      name="custLoyaltyNumber"
                      value={customerCopy.custLoyaltyNumber}
                      onChange={handleChange}
                      className="form-control form-control-sm text-center"
                    />
                  </div>
                  {customerCopy.custLoyaltyNumber === customer.custLoyaltyNumber &&
                    <p className='small mb-0 pb-0 text-center'>
                      <span className='pointer text-decoration-underline' onClick={e => download('/customer/loyaltyqrc')}>Télécharger le QR-Code</span>
                    </p>
                  }
                </div>}
              </div>
            </div>
            {debug(customerCopy)}
            <div className='pb-3'>
              <CustomAlert input={submission} onClose={e => setSubmission(null)} />
            </div>
            {<button type="button" onClick={handleSubmit} className="btn btn-lg btn-outline-blue">Sauvegarder</button>}
          </div>
        </div>
      </form >
    </div >
  );
};
export default CustomerAccount;
/*
border0 border-light rounded py-2
*/