// cartSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { getCartCumuls } from './../components/cart/cart.utils';
import API from './../api';

const cartSlice = createSlice({
  name: 'cart',
  initialState: window.sessionStorage.getItem('cart') ? { ...JSON.parse(window.sessionStorage.getItem('cart')) } : {
    isLoading: false,
    addressType: 'address',
    itemsCounts: {
      all: 0,
      kilo: 0,
      piece: 0,
    },
    totalAmounts: {
      all: 0,
      kilo: 0,
      piece: 0,
    },
    articles: [],
    consents: {}
  },
  reducers: {
    setCart: (state, action) => {


      if (!state.addressType) {
        action.payload.addressType = 'address';
      }

      Object.assign(state, { ...action.payload, isLoading: false });

      const cartCumuls = getCartCumuls(state.articles);
      Object.assign(state, { ...cartCumuls });
      sessionStorage.setItem('cart', JSON.stringify(state));
      // window.parent.postMessage({ type: 'cart', cart: { ...cartCumuls } }, '*');
    },
    clearCart: (state, action) => {
      sessionStorage.removeItem('cart');
      Object.assign(state, cartSlice.initialState);
      /*
      window.parent.postMessage({
        type: 'cart', cart: { itemsCounts: { all: 0 } }
      }, '*');
      */
      return { ...action.payload };
    },
    setCartItems: (state, action) => {
      state.items = action.payload;
      sessionStorage.setItem('cart', JSON.stringify(state));
    },
    setIsLoading: (state, bool) => {
      state.isLoading = bool.payload; // Marque le chargement comme en cours
    }
  },
});
export const { setCart, setCartItems, clearCart, setIsLoading } = cartSlice.actions; // Exporte setCart
export default cartSlice.reducer;
