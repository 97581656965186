// AuthGuard.js


import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom'; // Remplacement ici
import Modal from 'react-bootstrap/Modal';
import UserSignInModal from '../components/user/UserSignInModal';
const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const location = useLocation();
  useEffect(() => {
    console.log('AuthGuard ICI ', user)
    // if (!user.isSignedIn) {
    //  navigate('/'); 
    //}


  }, [user.isSignedIn, navigate]);
  const handleOnClose = () => {
    // navigate(-1);
  }

  console.log('AuthGuard ABCDE ', user)
  return (<>
    {user.isSignedIn ?
      children
      :
      <UserSignInModal onClose={handleOnClose} show={true} onSuccess={e => { }} />
    }
  </>)
}
export default AuthGuard;


