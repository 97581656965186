import { useEffect } from 'react';
import AuthGuard from './AuthGuard';
import Header from './../components/common/Header'
import { useSelector } from 'react-redux';
import './mainLayout.css';
const MainLayout = ({ children }) => {
  // const user = useSelector((state) => state.user);


  useEffect(() => {
    document.body.classList.add('main-layout');
    return () => {
      document.body.classList.remove('main-layout');
    };
  }, []);
  return (

    <AuthGuard>
      <div id="main-layout">
        {false && <Header />}
        <div className='content container0'>
          {children}
        </div>
      </div>
    </AuthGuard>

  );
};

export default MainLayout;