// CartDetails.js
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import API from './../../api';
import { getUnitPrice, getCartCumuls } from './priceUtils';
import { cloneDeep } from 'lodash';
import { formatPrice, swalInputBox, debug } from './../../utils'
import { FaRegTrashAlt, FaEdit } from 'react-icons/fa';

import { MdEdit } from 'react-icons/md';
import Picture from '../helpers/Picture/Picture';
import InputQuantity from '../helpers/InputQuantity/InputQuantity';
import './../../css/forms.css';
import './cart-details.css';
const CartDetails = ({ catalog = null, cart, disabled = false, onUpdate }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const [catalogObj, setCatalogObj] = useState(null);
  const [catalogCopy, setCatalogCopy] = useState(null);
  const [topCategories, setTopCategories] = useState([]);
  const [articlesPerCategories, setArticlesPerCategories] = useState(null)
  useEffect(() => {
    console.log('cart', cart)
    if (catalog === null) {
      API.get('/catalog').then(response => {
        setCatalogCopy(response.data);
      })
    }
  }, [])
  useEffect(() => {
    if (cart.articles && catalogObj) {
      const articlesPerCategoriesCopy = {}
      cart.articles.forEach(article => {
        const articleCopy = cloneDeep(article);
        articleCopy.product = catalogObj[articleCopy.prodId];
        if (!articlesPerCategoriesCopy[articleCopy.product.prodUnity]) {
          articlesPerCategoriesCopy[articleCopy.product.prodUnity] = {}
        }
        if (!articlesPerCategoriesCopy[articleCopy.product.prodUnity][articleCopy.parentId]) {
          const category = catalogObj[articleCopy.parentId];
          if (category.catParentId != '') {
            category.parent = catalogObj[category.catParentId]
          }
          articlesPerCategoriesCopy[articleCopy.product.prodUnity][articleCopy.parentId] = {
            category: category,
            articles: []
          };
        }
        articlesPerCategoriesCopy[articleCopy.product.prodUnity][articleCopy.parentId].articles.push(articleCopy);
      });
      setArticlesPerCategories(articlesPerCategoriesCopy);
    }
  }, [cart.articles, catalogObj])
  useEffect(() => {
    if (catalogCopy) {
      const objPrimary = { categories: 'catId', products: 'prodId', options: 'optId' };
      const catalogObjCopy = {}
      for (const k in catalogCopy) {
        catalogCopy[k].forEach(item => {
          catalogObjCopy[item[objPrimary[k]]] = item;
        })
      }
      setCatalogObj(catalogObjCopy);
      setTopCategories(catalogCopy.categories.filter(c => c.catParentId === ''));
    }
  }, [catalogCopy]);
  useEffect(() => {
    if (catalog) {
      setCatalogCopy(catalog);
    }
  }, [catalog]);
  const handleOptionsChange = (optId, artId) => {
    const articleIndex = cart.articles.findIndex(article => article.artId === artId); // Ici
    if (articleIndex !== -1) {
      const updatedArticle = { ...cart.articles[articleIndex] }; // Ici
      const options = { ...updatedArticle.options } || {}
      const optionIndex = Object.keys(updatedArticle.options).indexOf(optId);
      if (optionIndex !== -1) {
        delete (options[optId]);
      } else {
        options[optId] = catalogObj[optId];
      }
      updatedArticle.options = options;
      const optionsPriceTotal = Object.values(updatedArticle.options).reduce((acc, option) => acc + parseFloat(option.optPrice), 0);
      updatedArticle.totalPrice = (((Number(updatedArticle.unitPrice) + optionsPriceTotal) * updatedArticle.quantity)).toFixed(2);
      const updatedArticles = [...cart.articles];// Ici
      updatedArticles[articleIndex] = updatedArticle;
      onUpdate(updatedArticles);
    }
  };

  const handleArticleChange = (prop, value, artId) => {
    console.log('handleArticleChange')

    const articleIndex = cart.articles.findIndex(article => article.artId === artId); //
    if (articleIndex !== -1) {
      const updatedArticle = { ...cart.articles[articleIndex] };// Ici
      const product = catalogCopy.products.find(p => p.prodId === updatedArticle.prodId);
      updatedArticle[prop] = value;
      updatedArticle.unitPrice = getUnitPrice(product, updatedArticle.quantity);
      //updatedArticle.totalPrice = (updatedArticle.unitPrice * updatedArticle.quantity).toFixed(2);
      const optionsPriceTotal = Object.values(updatedArticle.options).reduce((acc, option) => acc + parseFloat(option.optPrice), 0);
      updatedArticle.totalPrice = ((Number(updatedArticle.unitPrice + optionsPriceTotal) * updatedArticle.quantity)).toFixed(2);
      const updatedArticles = [...cart.articles]; //Ici
      updatedArticles[articleIndex] = updatedArticle;
      onUpdate(updatedArticles);
    }
  }
  return (
    <div id="CartDetails" className='fs-7'>
      {catalogCopy && articlesPerCategories &&
        ['kilo', 'piece'].map(unity => (
          articlesPerCategories[unity] && (
            <table className='table table-sm table-striped0 table-bordered mb-5' key={unity}>
              <thead className='spacer'>
                <tr className='spacer'>
                  <th className='picture' style={{ width: '80px' }}></th>
                  <th></th>
                  <th style={{ width: '80px' }}></th>
                  <th style={{ width: '80px' }}></th>
                  <th style={{ width: '80px' }}></th>

                  {disabled === false &&
                    <th style={{ width: '80px' }}></th>
                  }
                </tr>
              </thead>
              <tbody>
                {topCategories.map(topCategory => {
                  // Cette vérification doit être à l'extérieur des accolades du bloc de code de map
                  if (!articlesPerCategories[unity]) return null;
                  const directArticles = articlesPerCategories[unity][topCategory.catId]?.articles || [];
                  const childCategoriesWithArticles = catalogCopy.categories.filter(c => c.catParentId === topCategory.catId && articlesPerCategories[unity][c.catId]);
                  if (directArticles.length === 0 && childCategoriesWithArticles.length === 0) return null;
                  return (
                    <React.Fragment key={topCategory.catId}>
                      <tr>
                        <th className='picture'></th>
                        <th colSpan={5}>{topCategory.catName}</th>
                      </tr>
                      {renderArticles(directArticles, unity, catalogObj, handleOptionsChange, formatPrice, handleArticleChange, disabled)}
                      {childCategoriesWithArticles.map(cat => renderArticles(articlesPerCategories[unity][cat.catId].articles, unity, catalogObj, handleOptionsChange, formatPrice, handleArticleChange, disabled))}
                    </React.Fragment>
                  );
                })}
              </tbody>
              <tfoot>
                {unity == 'piece' ? (
                  true && !user.isAdmin && <tr>
                    <td colSpan="2" className='fw-bold'>
                      Total
                      {cart?.itemsCounts?.kilo > 0 && <span> hors repassage du linge déjà lavé</span>}




                    </td>
                    <td></td>
                    <td className='text-center fw-bold'>{cart?.itemsCounts?.piece}</td>
                    <td className='text-center fw-bold'>{formatPrice(cart?.totalAmounts?.piece)} €</td>
                    <td></td>
                    {/*
                    <td colSpan={disabled === false ? 6 : 5} className='text-center text-red '>
                      <strong>Le montant total de votre commande additionné des frais de retrait/retour<br />sera déterminé par la repasserie lors de la réception de votre linge.</strong>
                    </td>
                */}
                  </tr>
                ) :
                  (
                    !user.isAdmin && <tr style={{ display: 'none' }}>
                      <td colSpan={disabled === false ? 6 : 5} className='text-center text-red'>
                        Ces articles feront l'objet d'un pesage lors de leur récéption à la Repasserie afin de calculer le coût de la prestation.
                      </td>
                    </tr>
                  )}
              </tfoot>
            </table>)))
      }


    </div >
  );
};
export default CartDetails;

function renderArticles(articles, unity, catalogObj, handleOptionsChange, formatPrice, handleArticleChange, disabled) {
  const articleTopCategorie = (article) => {

    let parentId = article.parentId;
    let category = null;
    while (parentId && parentId != '') {
      category = catalogObj[parentId]
      parentId = category.catParentId
    }
    return category;
  }
  const handleEditComment = async (article) => {
    const comment = article.comment ? article.comment : '';
    const topCategory = articleTopCategorie(article);
    let placeholder = 'Veuillez indiquer tout défaut existant sur le vêtement. Ex. déchirure';
    if (topCategory && topCategory.catName.toLowerCase() == 'repassage') {
      placeholder += ', tache'
    }
    placeholder += ', etc.';
    const result = await swalInputBox('text', comment, placeholder);

    if (typeof result != 'undefined') {
      handleArticleChange('comment', result, article.artId);
    }
  }
  return articles.map(article => (
    <tr key={article.artId}>
      <td className='picture'>
        {catalogObj[article.prodId].prodCover != '' &&
          <Picture src={catalogObj[article.prodId].prodCover} size="maxw=80,maxh=80" />
        }
      </td>
      <td>
        <div className="d-flex flex-column justify-content-between" style={{ height: 'auto' }}>
          {false && <div className='small'>{article.category?.catName || ''}</div>}
          <div>{article.product.prodDesignation}</div>
          {article.product.prodMemo !== '' && <div className='small'>{article.product.prodMemo}</div>}
          {disabled === false ? (
            <div>
              {catalogObj[article.prodId].prodOptions.length > 0 &&
                <>
                  {catalogObj[article.prodId].prodOptions.map(optionKey => (
                    <label key={optionKey} className='checkbox-label me-3 text-nowrap'>
                      <input
                        type="checkbox"
                        value="{optionKey}"
                        name="options"
                        checked={Object.keys(article.options).includes(optionKey)}
                        onChange={e => handleOptionsChange(optionKey, article.artId)}
                      />
                      {catalogObj[optionKey].optName} ({formatPrice(catalogObj[optionKey].optPrice)} €)
                    </label>
                  ))}
                </>
              }
            </div>
          ) : (
            <div>{Object.keys(article.options).map(optionKey => (
              <span className='d-inline-block me-3 text-nowrap' key={optionKey}>+ {catalogObj[optionKey].optName}</span>
            ))}
            </div>
          )}
          {article.parentId != 'cat5e34255b9050' && <div className="add-comment text-green">
            {disabled === false ? (
              <>
                {article.comment && article.comment !== '' ? (
                  <>
                    {article.comment}
                    <span title="Modifier le commentaire" className="pointer d-inline-block ms-2" onClick={e => handleEditComment(article)}><FaEdit /></span>
                  </>
                ) : (
                  <span className="pointer text-green" onClick={e => handleEditComment(article)}>Ajouter un commentaire</span>
                )}
              </>
            ) : (
              <>{article.comment}</>
            )}
          </div>}
        </div>
      </td>
      {article.unity == 'piece' &&
        <>
          <td className='text-center nowrap'>{formatPrice(article.unitPrice)} €</td>
          <td className='text-center  nowrap'>
            {(disabled === false && catalogObj[article.prodId]?.prodUnity !== 'kilo') ? (
              <InputQuantity quantity={article.quantity} disabled={false} onUpdate={q => handleArticleChange('quantity', q, article.artId)} />
            ) : (
              <span>{article.quantity}</span>
            )}
          </td>
          <td className='text-center  nowrap fw-semibold'>{article.totalPrice} €</td>
        </>
      }
      {article.unity == 'kilo' &&
        <td colSpan={3} className='text-center  nowrap'> {formatPrice(article.unitPrice)} € / kilo</td>
      }
      {disabled === false &&
        <td className='text-center  nowrap'>
          <span className='pointer' onClick={() => handleArticleChange('quantity', 0, article.artId)}><FaRegTrashAlt /></span>
        </td>
      }
    </tr>
  ));
}

