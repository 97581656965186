import React, { useState, useEffect } from 'react';
import { BsCheckCircle, BsCircle } from "react-icons/bs";
import { useSelector } from 'react-redux'; // Importe useSelector
import { debug, isDev } from './../../../utils';

const PasswordChecker = ({ password, isValid }) => {
  const config = useSelector((state) => state.config);
  const passwordRegex = new RegExp(config.form_rules.password.regex.slice(1, -1))// /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%&#])[A-Za-z\d@$!%&#]{9,18}/;
  const specialRegex = new RegExp("[" + config.form_rules.password.include.special + "]");
  useEffect(() => {
    isValid(passwordRegex.test(password));
  }, [password]);
  return (
    <div>
      <ul className='password-features small'>
        <li className='d-flex align-items-center'>
          {password.length >= config.form_rules.password.length.min && password.length <= config.form_rules.password.length.max ? <BsCheckCircle className="ok" /> : <BsCircle className="ko" />}
          &nbsp;Entre {config.form_rules.password.length.min} et {config.form_rules.password.length.max}
          caractères
        </li>
        {config.form_rules.password.include.uppercase &&
          <li className='d-flex align-items-center'>{password.match(/[A-Z]/) ? <BsCheckCircle className="ok" /> : <BsCircle className="ko" />}&nbsp;Une majuscule  minmum</li>
        }
        {config.form_rules.password.include.lowercase &&
          <li className='d-flex align-items-center'>{password.match(/[a-z]/) ? <BsCheckCircle className="ok" /> : <BsCircle className="ko" />}&nbsp;Une minuscule minmum</li>
        }
        {config.form_rules.password.include.number &&
          <li className='d-flex align-items-center'>{password.match(/[0-9]/) ? <BsCheckCircle className="ok" /> : <BsCircle className="ko" />}&nbsp;Un chiffre minmum</li>
        }
        {config.form_rules.password.include.special &&
          <li className='d-flex align-items-center'>{specialRegex.test(password) ? <BsCheckCircle className="ok" /> : <BsCircle className="ko" />}&nbsp;Un caractère spécial ({config.form_rules.password.include.special}) minmum</li>
        }
      </ul>
      <>{false && (passwordRegex.test(password) ? ('ok') : ('nop'))}</>
    </div>
  );
};
export default PasswordChecker;
