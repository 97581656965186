
// AdminPickups.js
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import API from './../../../api';
import Modal from 'react-bootstrap/Modal';
import AdminPickup from './AdminPickup';
import { useDispatch, useSelector } from 'react-redux';
import { debug, removeAccents, swalAlert } from './../../../utils';
import Switcher from './../../helpers/Switcher/Switcher'

import { MdOpenInNew, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdManageSearch, MdAdd } from "react-icons/md";
import './../../../css/forms.css';
import './admin-pickups.css';
function AdminPickups() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [pickups, setPickups] = useState([]);
  const [editedPickup, setEditedPickup] = useState(null);
  const [filters, setFilters] = useState({ active: '', inactive: '' });
  useEffect(() => {
    get_pickups();
  }, [
  ]);
  const get_pickups = async () => {
    const response = await API.get('/admin/pickups');
    setPickups(response.data);
  }
  useEffect(() => {
    const pickup = pickups.find(p => p.picId == 'pic65b979aa70842');
    if (pickup) {

    }
  },
    []
  )
  const filterMatch = (filter, citName = '') => {
    return removeAccents(citName).toLowerCase().includes(removeAccents(filter).toLowerCase());
  }
  const handleCreatePickup = () => {
    const currentDate = moment();
    const picNextOccurrenceWeek = currentDate.add(1, 'week').format('YYYY-[W]WW');
    setEditedPickup({
      picStatus: 'active',
      picNextOccurrenceWeek: picNextOccurrenceWeek,
      cities: [],
      occurrences: [],
      picDefaultSlot: "",
      picDefaultSlotStart: "08:30",
      picDefaultSlotEnd: "17:30",
      has_occurrences: false,
      dirtyFields: []
    })
  }
  const handleEditPickup = async (picId) => {
    const response = await API.get('/admin/pickup/' + picId);
    setEditedPickup({ ...response.data, dirtyFields: [] });
  }
  const handleOnCreated = (pickup) => {
    setPickups([...pickups, pickup]);
  }
  const handleOnUpdated = (updatedPickup) => {
    setPickups(pickups.map(pickup =>
      pickup.picId === updatedPickup.picId ? updatedPickup : pickup
    ));
  }
  const handleCloseModal = () => {
    setEditedPickup(null);
  }
  return (
    <div id="AdminPickups">
      <div className='container'>
        <div className='content-title'>
          <h1 className=''>Administration des tournées </h1>
          <button className='btn btn-outline-secondary' onClick={handleCreatePickup}>
            <MdAdd size={25} className="position-relative" style={{ 'top': '-2px' }} />  Créer une tournée
          </button>
        </div>
        {pickups.length > 0 ?
          <table className='table table-striped table-sm'>
            <tbody>
              {
                pickups.map(pickup => (
                  <tr key={pickup.picId}>
                    <td style={{ width: '25px' }}>{
                      pickup.picStatus == 'active' ? <img src="/img/maps/red-marker.png" height={21} /> : <img src="/img/maps/grey-marker.png" height={21} />} </td>
                    <td>{pickup.picName}</td>
                    <td>{pickup.picDay ? t(`day-${pickup.picDay}`) : ''}</td>
                    <td>{pickup.picDefaultSlot}</td>
                    <td className='text-end'><span className='pointer' onClick={e => handleEditPickup(pickup.picId)} > <MdOpenInNew size={14} /></span></td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          :
          <div className='alert alert-warning py-2 px-3 small'>
            Vous n'avez créé aucune tournée.
          </div>
        }
      </div >
      {false && debug(pickups)}
      {editedPickup &&
        <Modal show={true} onHide={handleCloseModal} className="admin-pickup-modal" size="xl" keyboard={false} backdrop="static">
          <Modal.Header closeButton>
            <div className='row flex-grow-1' >
              <div className='col-sm-4'>
                <Modal.Title>
                  {/* <span className='display-inline-block position-relative me-2' style={{ top: "-2px" }}>
                    { editedPickup.picStatus === 'active' ? <img src="/img/maps/red-marker.png" height={25} /> : <img src="/img/maps/grey-marker.png" height={25} /> }
                  </span>*/}
                  {editedPickup.picName != '' ? editedPickup.picName : 'Nouvelle tournée'}
                </Modal.Title>
              </div>
              <div className='col-sm-8 text-end pe-4'>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <AdminPickup pickup={editedPickup} onClose={handleCloseModal} onCreated={handleOnCreated} onUpdated={handleOnUpdated} />
          </Modal.Body>
          <Modal.Footer>
            <div className='text-left flex-grow-1'>
              {debug(editedPickup.picId, 0)}
              {debug(editedPickup.dirtyFields, 0)}
            </div>
          </Modal.Footer>
        </Modal>}
    </div >
  );
}
export default AdminPickups;
