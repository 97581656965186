import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import cartReducer from './cartSlice';
import configReducer from './configSlice';
import utilsReducer from './utilsSlice';
const store = configureStore({
  reducer: {
    user: userReducer,
    cart: cartReducer,
    config: configReducer,
    utils: utilsReducer,
  },
});
export default store;
